import baseComponent from "../general/baseComponent";
import React from "react";

class DataRow extends baseComponent {
    state = {
        style: {},
        useStateStyle: false
    }

    props = {
        style: {},
        i: -1,
        selectedIdx: -1,
        row_clicked: () => {
        },
        children: [(<div/>)]
    }

    constructor(props: any) {
        super(props);
        this.props = props;

        this.setState({
            style: this.props.style
        })
    }

    render() {
        return (
            <div className={"row datarow" + (this.props.selectedIdx === this.props.i ? " selected_row" : "")}
                 onClick={(event => this.props.row_clicked())}
                 style={this.state.useStateStyle ? this.state.style : this.props.style}>
                {this.props.children}
            </div>);
    }
}

export default DataRow;