function parseEmbeded(raw: string) {
    let str: string = raw;

    let elem = [];

    let i = 0;

    while (str.includes("{") && str.includes("}")) {
        let start = raw.indexOf("{");
        let end = raw.indexOf("}");
        let embStr = str.substr(start + 1, end - start - 1);

        let embStrSeg = embStr.split(",");

        elem.push(str.substr(0, start));

        if (embStrSeg[0] == "link") {
            elem.push(<a key={i} href={embStrSeg[1]} target="_blank" rel="noreferrer">{embStrSeg[2]}</a>);
        }

        str = str.substr(end);
        i++;
    }

    if (i == 0) {
        elem.push(str);
    }

    return elem;
}

export default parseEmbeded;