import React from 'react';
import './styling/shared.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/main";
import Login from "./pages/login/login";
import justlogs from "./scripts/justlogs";
import ErrorReports from "./components/shared/errorReports";

class App extends React.Component {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        justlogs.getGrantIfNoGrant();
        document.head.innerHTML += "<link href=\"https://fonts.googleapis.com/icon?family=Material+Icons\" rel=\"stylesheet\">";
        document.head.innerHTML += "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />";
    }

    render() {
        return (<div className="masterBody">
            <BrowserRouter>
                <Routes>
                    <Route path="/main/*" element={<Main/>}/>
                    <Route path="*" element={<Login/>}/>
                </Routes>
            </BrowserRouter>
            <ErrorReports/>
        </div>);
    }
}

export default App;
