import Popup from "../general/popup";
import DataView from "../data/dataView";
import React from "react";
import DataGrid2 from "../data/dataGrid2";
import customFetch from "../../scripts/customFetch";
import DataRow from "../data/dataRow";
import DataManager from "../data/dataManager";

class FileUpload extends Popup {
    state = {
        hh_granted: false,
        fileChangeE: null,

        page: 0,
        visible: false,
        colour: "primary",
        header_text: "File Upload",
        body_text: "Body Text",
        min_width: "40vw",

        onClose: this.hide
    }

    props = {
        data_view: React.createRef<DataView>(),
        data_field_idx: 0,

        ...Popup.defaultProps,

        onClose: () => {
        }
    }
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_view = React.createRef<DataView>();
    }

    embedFile() {
        let fvm = this.props.data_view.current?.state.field_value_memory;
        let row = this.data_grid.current?.state.data_rows[this.data_grid.current?.state.selectedIdx as number] as any;

        if (fvm && row) {
            fvm[this.props.data_field_idx] += "{link,https://portalapi.etherlive.com/api/crud/files/download/" + row["fileName"] + "." + row["fileExt"] + ",Default File Description}";
            this.props.data_view.current?.setState({field_value_memory: fvm});
            this.hide();
        }
    }

    body_html(): JSX.Element {
        return (
            <div>
                <DataGrid2 ref={this.data_grid} url="crud/files/search"
                           row_selector={[]}
                           header_titles={["Name", "Description", "Uploaded At", "Content Type", "Creator"]}
                           header_sizes={[2, 3, 2, 2, 3]}
                           additonal_row_selector={[]}
                           cell_body_extractors={[
                               (d: any) => {
                                   return (
                                       <a href={"https://portalapi.etherlive.com/api/crud/files/download/" + d["fileName"] + "." + d["fileExt"]}
                                          target="_blank">{d["fileName"]}</a>)
                               },
                               (d: any) => {
                                   return (<div>{d["desc"]}</div>)
                               },
                               (d: any) => {
                                   return (<div>{d["uploadedAt"].replace("T", " @ ")}</div>)
                               },
                               (d: any) => {
                                   return (<div>{d["contentType"] != null ? d["contentType"] : "No Content"}</div>)
                               },
                               (d: any) => {
                                   return (
                                       <div>{d["createdByEmail"]}</div>)
                               }
                           ]}
                           row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                               this.data_view.current?.data_grid_row_selected(index, row_data)
                           }}
                />
                <DataManager data_grid={this.data_grid} action_url="crud/files"
                             add_enabled={true} update_enabled={true} delete_enabled={true}
                             data_view={this.data_view}
                             update_data_key={[]}
                             default_obj={{desc: "Default Name"}}
                />
                <button className="btn btn-success" onClick={(event => {
                    this.uploadPressed()
                })} type="button">Upload File
                </button>
                <input type="file" multiple={false} onChange={(event => this.remeberFile(event))}/>
                <DataView ref={this.data_view} data_grid={this.data_grid}
                          field_labels={["Name", "Description"]}
                          field_value_selector={[["fileName"], ["desc"]]}
                          field_readonly={[true, false]}
                          field_types={["string", "string"]}
                />
                <button className="btn btn-success" onClick={(event => {
                    this.embedFile()
                })} type="button">Embed Selected
                </button>
            </div>);
    }

    remeberFile(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({fileChangeE: e});
    }

    uploadFile(content: any, fileType: any, fileName: any) {
        let that = this;
        let row = this.data_grid.current?.state.data_rows[this.data_grid.current?.state.selectedIdx as number] as any;
        customFetch("crud/files/upload/" + row["id"] + "?fname=" + fileName,
            (d) => {
                that.data_grid.current?.reload();
            },
            (d) => {
            },
            {
                method: "POST",
                headers: {
                    "Content-Type": fileType
                },
                body: content
            }
        )
    }

    uploadPressed() {
        if (this.data_grid.current?.state.selectedIdx != -1) {
            if (this.state.fileChangeE != null) {
                let oe = this.state.fileChangeE as React.ChangeEvent<HTMLInputElement>;
                if (oe != null && oe.target != null && oe.target.files != null) {
                    let file = oe.target.files[0];

                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const text = window.btoa(e.target?.result as string)
                        this.uploadFile(text, file.type, file.name)
                    };
                    reader.readAsBinaryString(file);
                }
            }
        }
    }
}

export default FileUpload;
