import React from "react";

class baseComponent extends React.Component {
    padToTwoInt(n: number, intDigits: number = 2) {
        return n.toLocaleString('en-US', {
            minimumIntegerDigits: intDigits,
            useGrouping: false
        });
    }

    onValChange(event: any) {
        if (event.target.type == "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    onKeyCheckFocusFlow(e: any, nextFocus: any, action: any) {
        if (e.key == 'Enter') {
            e.preventDefault();
            if (action != null) {
                action();
            }
            if (nextFocus != null) {
                nextFocus.current?.focus();
            }
        }
    }
}

export default baseComponent;