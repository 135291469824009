import React from 'react';
import '../../styling/bootstrap.scss'

class NavGridItem extends React.Component {
    static defaultProps = {
        goto: "",
        onClick: () => {
        },
        disabled: false,
        newTab: false
    };
    props = {
        goto: "",
        onClick: () => {
        },
        colour: "info",
        children: (<p/>),
        disabled: false,
        newTab: false
    }

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    navigateTo(that: any) {
        if (that.props.newTab) window.open(that.props.goto, "_blank");
        else document.location.href = that.props.goto;
    }

    render() {
        let btnFunc = this.props.goto !== "" ? this.navigateTo : this.props.onClick;
        let that = this;
        return (
            <div className="col-sm">
                <button className={"btn btn-" + this.props.colour} onClick={(event => btnFunc(that))} type="button"
                        disabled={this.props.disabled}>
                    {this.props.children}
                </button>
            </div>);
    }
}

export default NavGridItem;