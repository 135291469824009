import React from "react";
import DataInterface from "./dataInterface";
import DataRow from "./dataRow";
import '../../styling/bootstrap.scss'
import './dataGrid.scss'

class DataGrid2 extends DataInterface {
    static defaultProps = {
        ...DataInterface.defaultProps,
        cell_styler: [],
        row_styler: (row_data: any) => {
            return {}
        },

        row_clicked: (index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
        },
        maxHeight: "60vh"
    }
    state = {
        ...DataInterface.baseState,
        header: (<div></div>)
    }
    props = {
        ...DataInterface.baseProps,
        header_titles: [] as any [],
        header_sizes: [12],

        cell_body_extractors: [(row_data: any) => {
            return (<p></p>)
        }],
        cell_styler: [(row_data: any, cell_value: any) => {
            return {}
        }],
        row_styler: (row_data: any) => {
            return {}
        },

        row_clicked: (index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
        },

        maxHeight: "60vh"
    }

    private rowElem: React.RefObject<any>;
    private row_elements: React.RefObject<DataRow>[] = [];

    constructor(props: any) {
        super(props);
        this.props = props;

        this.rowElem = React.createRef<any>();

        //enforced prop arr length
        let epal = this.props.header_titles.length;
        if (this.props.header_sizes.length != epal || this.props.cell_body_extractors.length != epal ||
            (this.props.cell_styler.length != 0 && this.props.cell_styler.length != epal)) {
            throw "DataGrid Prop Arrays Must Match In Length";
        }
    }

    componentDidMount() {
        this.setState({
            header: this.generateHeader()
        })
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.rowElem.current?.clientHeight <= this.rowElem.current?.scrollHeight) {
            this.loadPageUsingThis();
        }
    }

    render() {
        return (<div className="container custom_grid">
            {this.state.header}
            <div className="custom_grid_rows" ref={this.rowElem} style={{maxHeight: this.props.maxHeight}}
                 onScroll={(event => {
                     this.viewScrolled(event)
                 })}>
                {this.generateRows()}
            </div>
            <div style={{float: "right"}}>
                <button className="btn btn-info" onClick={(event => {
                    this.loadPageUsingThis();
                })} type="button">Load More
                </button>
            </div>
        </div>);
    }

    public revertRowClicked() {
        let idx = this.state.lastSelectedIDX != -1 ? this.state.lastSelectedIDX : this.state.selectedIdx;
        this.setState({
            selectedIdx: idx,
            lastSelectedIDX: idx
        });

        this.props.row_clicked(idx, this.state.data_rows[idx], this.row_elements[idx]);
    }

    private generateHeader() {
        let rows: JSX.Element[] = [];

        this.props.header_titles.forEach((v: string, i: number, a: string[]) => {
            rows.push(<div key={i} className={"col-sm-" + this.props.header_sizes[i]}>{v}</div>)
        })

        return (
            <div className="row header">
                {rows}
            </div>
        );
    }

    private viewScrolled(event: React.UIEvent): void {
        let percDown = (event.currentTarget.scrollTop + event.currentTarget.clientHeight) / event.currentTarget.scrollHeight;
        if (percDown > 0.70) {
            if (!this.blockLoad) {
                this.loadPageUsingThis();
            }
        }
    }

    private generateRowCells(row: any): JSX.Element[] {
        let cols: JSX.Element[] = [];

        this.props.cell_body_extractors.forEach((v: (row_data: any) => JSX.Element, i: number) => {
            let val = v(row);
            let style = this.props.cell_styler.length > 0 ? this.props.cell_styler[i](row, val) : {};
            cols.push(<div key={i} style={style} className={"col-sm-" + this.props.header_sizes[i]}>{val}</div>);
        })

        return cols;
    }

    private generateRows(): JSX.Element[] {
        let rows: JSX.Element[] = [];
        let row_refs: React.RefObject<DataRow>[] = [];

        this.state.data_rows.forEach((row: any, i: number, a: any[]) => {
            row_refs.push(React.createRef());
            rows.push(
                <DataRow key={i} i={i} ref={row_refs[i]} style={this.props.row_styler(row)}
                         selectedIdx={this.state.selectedIdx}
                         row_clicked={() => this.preRowClicked(i, row, row_refs[i])}>{this.generateRowCells(row)}</DataRow>
            );
        });

        this.row_elements = row_refs;
        return rows;
    }

    private preRowClicked(index: number, row_data: any, row_element: React.RefObject<DataRow>) {
        this.setState({
            selectedIdx: index,
            lastSelectedIDX: this.state.selectedIdx
        });
        this.props.row_clicked(index, row_data, row_element);
    }
}

export default DataGrid2;
