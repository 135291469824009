import DataGrid from "../data/dataGrid";

class ReportView extends DataGrid {

    constructor(props: any) {
        super(props);
    }

    pageParams(state: any, props: any) {
        const search = window.location.search;
        let id = new URLSearchParams(search).get('id');
        return "?keys=jobId&values=" + id + "&page=" + state.page;
    }
}

export default ReportView;