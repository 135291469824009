import Page from "../../page";
import NavGridItem from "../../../components/shared/navGridItem";
import React from "react";
import doFetch from "../../../scripts/fetch";

class Downloads extends Page {
    state = {
        hh_granted: false,
        perc: 0,
        looks: 0,
        require_admin: true
    }
    private loadIDX = -1;

    componentDidMount() {
        this.requireHH();
    }

    saveFile(csv: string) {
        csv = "ID,Company,Job Name,Depot,Live Date,Account Manager,Project Manager,Status,Revenue,Forecast %,Revenue Factored Down,Currency Exchange,AUD Factored Revenue,Type,Month,Year\n" + csv;

        var file = new Blob([csv],
            {type: "text/plain;charset=utf-8"});


        var a = document.createElement("a"),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = "HHJobSalesReport.csv";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    checkLoadState() {
        doFetch("misc/downloader/hhjobs?loadidx=" + this.loadIDX, "GET",
            (d) => {
                if (d.hasOwnProperty("detail")) {
                    this.setState({
                        perc: (this.state.looks / (this.state.looks < 100 ? 100 : this.state.looks + 1) * 100),
                        looks: this.state.looks + 1
                    });
                    this.checkLoadState();
                } else {
                    this.setState({perc: 100});
                    this.saveFile(d["csv"]);
                }
            },
            (d) => {

            });
    }

    loadJobSales() {
        doFetch("misc/downloader/hhjobs", "GET",
            (d) => {
                this.loadIDX = parseInt(d);
                this.checkLoadState();
            },
            (d) => {

            });
    }

    renderHHRequired(): JSX.Element {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="./home">Home</a></li>
                <li className="breadcrumb-item"><a href="./admin">Admin</a></li>
                <li className="breadcrumb-item">Downloads</li>
            </ol>
            <div className="container navgrid">
                <div className="row">
                    <NavGridItem colour="warning" newTab={true}
                                 goto="https://portalapi.etherlive.com/api/misc/downloader/hhids"><p>Download HH Job ID
                        & Name List</p>
                    </NavGridItem>
                    <NavGridItem colour="danger" onClick={() => {
                        this.setState({perc: 0.1, looks: 1});
                        this.loadJobSales();
                    }}>
                        <div>
                            <p>Download HH Job Sales Report<br/><small>This will saturate your HH use.</small></p>
                            {
                                (this.state.perc > 0 ? (
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            style={{width: this.state.perc + "%"}}/>
                                        <div className="progress-bar bg-warning"
                                             role="progressbar"
                                             style={{width: (100 - this.state.perc) + "%"}}/>
                                    </div>
                                ) : (<div></div>))
                            }

                        </div>
                    </NavGridItem>
                </div>
            </div>
        </div>)
    }
}

export default Downloads;
