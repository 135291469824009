import Page from "../../page";
import DataGrid2 from "../../../components/data/dataGrid2";
import React from "react";
import Card from "../../../components/general/card";
import DataView from "../../../components/data/dataView";
import doFetch from "../../../scripts/fetch";
import Alert from "../../../components/general/alert";

class MyAccount extends Page {
    state = {
        hh_granted: false,
        cur_password: "",
        new_password: "",
        require_admin: false
    }

    private data_view: React.RefObject<DataView>;
    private data_grid: React.RefObject<DataGrid2>;
    private readonly fpError: React.RefObject<Alert>;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.data_view = React.createRef<DataView>();
        this.data_grid = React.createRef<DataGrid2>();
        this.fpError = React.createRef<Alert>();
    }

    componentDidMount() {
        doFetch("crud/user/myaccount", "get",
            (data: any) => {
                this.data_view.current?.data_grid_row_selected(0, data);
            },
            (data: any) => {
            }
        );
    }

    deleteHHClicked() {
        var that = this;
        doFetch("auth/hirehop/remove", "delete",
            (data: any) => {
                document.location.reload();
            },
            (data: any) => {
                that.fpError.current?.changeDetails("warning", "An Error Occurred", data["detail"], true);
            }
        );
    }

    changeFPPassword() {
        var that = this;
        doFetch("user/password/change", "post",
            (data: any) => {
                that.fpError.current?.changeDetails("success", "Password Updated!", "", true);
            },
            (data: any) => {
                that.fpError.current?.changeDetails("warning", "An Error Occurred", data["detail"], true);
            },
            {
                oldPassword: that.state.cur_password,
                newPassword: that.state.new_password
            }
        );
    }

    renderAlways() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="./home">Home</a></li>
                <li className="breadcrumb-item">Your Account</li>
            </ol>
            <Card colour="info" title="Account Details">
                <div>
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Email", "HireHop Email", "Permissions"]}
                              field_value_selector={[["user"], ["hh_email"], ["permissions"]]}
                              field_readonly={[true, true, true]}
                              field_types={["string", "string", "string"]}
                    />
                </div>
            </Card>
            <Card colour="warning" title="Change Password">
                <div>
                    <label className='col-form-label mt-2'>Please Enter Your <strong>Current</strong> Field Portal
                        Password</label>
                    <input className='form-control' placeholder='Password' type='password'
                           value={this.state.cur_password} name="cur_password"
                           onChange={(event => this.onValChange(event))}
                           onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, null))}/>
                    <label className='col-form-label mt-2'>Please Enter Your <strong>New</strong> Field Portal Password</label>
                    <input className='form-control' placeholder='Password' type='password'
                           value={this.state.new_password} name="new_password"
                           onChange={(event => this.onValChange(event))}
                           onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, null))}/>
                    <button className="btn btn-warning" onClick={(event => {
                        this.changeFPPassword();
                    })} type="button">Change Field Portal Password
                    </button>
                    {(this.data_view.current?.state.row_data != null && (this.data_view.current?.state.row_data as any)["hh_email"].length > 0) ?
                        <button className="btn btn-danger" onClick={(event => {
                            this.deleteHHClicked();
                        })} type="button">Disconnect HireHop Account
                        </button>
                        :
                        <button className="btn btn-info" onClick={(event => {
                            this.props.navBar.current?.connectPressed();
                        })} type="button">Connect HireHop Account
                        </button>
                    }

                    <Alert ref={this.fpError}/>
                </div>
            </Card>
        </div>)
    }
}

export default MyAccount;
