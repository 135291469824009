import React from "react";
import './alert.scss'

class Alert extends React.Component {
    static defaultProps = {
        colour: "warning",
        strongText: "Example Strong",
        weakText: "Weak Example",
        visible: false
    }
    state = {
        colour: "warning",
        strongText: "Example Strong",
        weakText: "Weak Example",
        visible: false
    }
    props = {
        colour: "warning",
        strongText: "Example Strong",
        weakText: "Weak Example",
        visible: false
    }

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.changeDetails(this.props.colour, this.props.strongText, this.props.weakText, this.props.visible);
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps != this.props) {
            this.setState(this.props);
        }
    }

    changeDetails(_colour: string, _strong: string, _weak: string, _visible: boolean) {
        this.setState({
            colour: _colour,
            strongText: _strong,
            weakText: _weak,
            visible: _visible
        });
    }

    hide() {
        this.setState({visible: false});
    }

    show() {
        this.setState({visible: true});
    }

    render() {
        if (this.state.visible) {
            return (
                <div className={'alert alert-' + this.state.colour}>
                    <button className='btn btn-close' type='button' onClick={() => this.hide()}/>
                    <strong>{this.state.strongText}</strong> <a>{this.state.weakText}</a>
                </div>
            )
        } else return null;
    }
}

export default Alert;
