import React from "react";
import Page from "../page";
import NavGridItem from "../../components/shared/navGridItem";

class Admin extends Page {
    constructor(props: any) {
        super(props);
        this.props = props;
    }

    state = {
        hh_granted: false,
        require_admin: true
    }

    renderAlways() {
        return (
            <div>
                <div className="container navgrid">
                    <div className="row">
                        <NavGridItem colour="primary" goto="./crud/generalupdates"><p>Manage General
                            Updates</p>
                        </NavGridItem>
                        <NavGridItem colour="primary" goto="./crud/announcements"><p>Manage Safety
                            Announcements</p></NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="primary" goto="./crud/operatingprocedures" disabled={true}><p>Manage
                            Operating
                            Procedures</p></NavGridItem>
                        <NavGridItem colour="primary" goto="./crud/defectreporting" disabled={true}><p>Defect
                            Reporting</p>
                        </NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="warning" goto="./downloads"><p>HH Downloads / Exports</p></NavGridItem>
                        <NavGridItem colour="info" goto="./crud/logs"><p>Logs</p></NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="warning" goto="./crud/users"><p>Manage Users</p></NavGridItem>
                        <NavGridItem colour="dark" goto="./home"><p>Home</p></NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="danger" goto="./crud/files"><p>Files</p></NavGridItem>
                        <NavGridItem colour="danger" goto="./crud/configurables"><p>Configurables</p></NavGridItem>
                    </div>
                </div>
            </div>);
    }
}

export default Admin;
