import NavBar from "../components/shared/navBar";
import Footer from "../components/shared/footer";
import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./main/home";
import Admin from "./main/admin";
import JobManager from "./main/home/jobmanager";
import JobReport from "./main/home/jobreport";
import Users from "./main/admin/users";
import SafetyAnnouncements from "./main/admin/safetyAnnouncements";
import GeneralAnnouncements from "./main/admin/generalAnnouncement";
import Logs from "./main/admin/logs";
import Configurables from "./main/admin/configurables";
import FileUploadPage from "./main/admin/fileUploadPage";
import JobAssets from "./main/home/jobassets";
import Downloads from "./main/admin/downloads";
import MyAccount from "./main/home/myaccount";

class Main extends React.Component {
    private readonly nav_bar: React.RefObject<NavBar>;

    constructor(props: any) {
        super(props);
        this.nav_bar = React.createRef<NavBar>();
    }

    render() {
        return (<div>
            <NavBar ref={this.nav_bar}/>
            <Routes>
                <Route path="/home" element={<Home navBar={this.nav_bar}/>}/>
                <Route path="/myaccount" element={<MyAccount navBar={this.nav_bar}/>}/>
                <Route path="/jobmanager" element={<JobManager navBar={this.nav_bar}/>}/>
                <Route path="/jobreport" element={<JobReport navBar={this.nav_bar}/>}/>
                <Route path="/jobassets" element={<JobAssets navBar={this.nav_bar}/>}/>

                <Route path="/admin" element={<Admin navBar={this.nav_bar}/>}/>
                <Route path="/crud/users" element={<Users navBar={this.nav_bar}/>}/>
                <Route path="/crud/announcements" element={<SafetyAnnouncements navBar={this.nav_bar}/>}/>
                <Route path="/crud/generalupdates" element={<GeneralAnnouncements navBar={this.nav_bar}/>}/>
                <Route path="/crud/configurables" element={<Configurables navBar={this.nav_bar}/>}/>
                <Route path="/crud/files" element={<FileUploadPage navBar={this.nav_bar}/>}/>
                <Route path="/crud/logs" element={<Logs navBar={this.nav_bar}/>}/>
                <Route path="/downloads" element={<Downloads navBar={this.nav_bar}/>}/>
            </Routes>
            <Footer/>
        </div>);
    }
}

export default Main;
