import NavBar from "../components/shared/navBar";
import Page from "../pages/page";
import JustLogs from "../scripts/justlogs";

export let preUrl = document.location.href.includes("localhost") ? "https://localhost:5001/api/" : "https://portalapi.etherlive.com/api/";

function customFetch(url: string, success: (d: any) => void, fail: (d: any) => void, _options: any = {}) {

    let optn = {..._options, credentials: "include"};

    if (document.location.href.includes("localhost")) {
        optn.headers = {
            ...optn.headers,
            uid: localStorage.getItem('uid'),
            token: localStorage.getItem('token'),
            'Sec-Fetch-Mode': 'no-cors'
        }
    }

    fetch(preUrl + url, optn)
        .then(
            response => {
                if (response.status.toString().startsWith("2")) {
                    return response.json()
                        .catch(() => {
                            return {good: true};
                        })
                }
                else {
                    switch (response.status) {
                        case 401:
                            if (!url.includes("auth")) {
                                if (NavBar.cur_navbar) {
                                    NavBar.cur_navbar.doLoginChecks(Page.cur_page);
                                }
                            }
                            break;
                        case 403:
                            document.location.href = "/main/home";
                            break;
                    }

                    return response.json()
                        .then((value => {
                            return {data: value, good: false};
                        }))
                        .catch(() => {
                            return {good: false};
                        });
                }
            })
        .then(data => {
            if ((data.hasOwnProperty("good") && !data["good"]) || (data.hasOwnProperty("status") && !data["status"].toString().startsWith("2") && data.hasOwnProperty("detail"))) {
                delete optn.headers.token;
                delete optn.headers.password;
                delete optn.headers.userpassword;
                delete optn.headers.hhpassword;
                delete optn.headers.oldPassword;
                delete optn.headers.newPassword;
                JustLogs.addLog(url, JSON.stringify({optn, data: data["data"]}));
                fail(data["data"]);
            } else {
                success(data);
            }
        })
}

export default customFetch;
