import baseComponent from "../general/baseComponent";
import customFetch from "../../scripts/customFetch";
import React from "react";

class DataInterface extends baseComponent {
    static baseState = {
        data_rows: [],
        selectedIdx: -1,
        lastSelectedIDX: -1
    };
    static baseProps = {
        url: "",
        page_size: 50,
        url_paramaters: (state: Readonly<{}>, props: Readonly<{ page_size: number }>, page_num: number) => {
            return ""
        },
        request_options: (state: Readonly<{}>, props: Readonly<{}>) => {
            return {}
        },

        row_selector: [""],
        additonal_row_selector: [""]
    }
    static defaultProps = {
        page_size: 50,
        url_paramaters: (state: Readonly<{}>, props: Readonly<{ page_size: number }>, page_num: number) => {
            return "?keys=&values=&count=" + props.page_size + "&page=" + page_num
        },
        request_options: (state: Readonly<{}>, props: Readonly<{}>) => {
            return {
                method: "get",
                headers: {}
            }
        },

        row_selector: [""],
        additonal_row_selector: []
    }
    props = DataInterface.baseProps;
    state = DataInterface.baseState;
    public additional_row_data = [];
    protected blockLoad = false;
    private page_num: number = 0;
    private row_max: number = -1;
    private loadAfterUpdate = false;

    public updateSelectedIdx(data: any, row_key: string[]) {
        let d = [].concat(this.state.data_rows);

        switch (row_key.length) {
            case 0:
                d[this.state.selectedIdx] = data as never;
                break;
            case 1:
                d[this.state.selectedIdx][row_key[0]] = data as never;
                break;
            case 2:
                d[this.state.selectedIdx][row_key[0]][row_key[1]] = data as never;
                break;
            case 3:
                d[this.state.selectedIdx][row_key[0]][row_key[1]][row_key[2]] = data as never;
                break;
        }
        this.setState({data_rows: d});
    }

    public reload() {
        this.page_num = 0;
        this.row_max = -1;
        this.additional_row_data = [];
        this.blockLoad = false;
        this.loadAfterUpdate = true;
        this.setState({
            data_rows: [],
            selectedIdx: -1,
            lastSelectedIDX: -1
        });
    }

    componentDidMount() {
        this.loadPageUsingThis();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.loadAfterUpdate) {
            this.loadAfterUpdate = false;
            this.loadPageUsingThis();
        }
    }

    public loadPageUsingThis() {
        this.loadPage(this.state, this.props);
    }

    private isAllPagesLoaded() {
        return this.state.data_rows.length == this.row_max;
    }

    private extractRowData(data: any, selector_chain: string[]) {
        let d = data;
        selector_chain.forEach((v: string, i: number, a: string[]) => {
            d = d[v];
        })
        return d;
    }

    private loadPage(state: Readonly<{}>, props: Readonly<{ page_size: number }>) {
        if (this.isAllPagesLoaded() || this.blockLoad) {
            return;
        }

        this.blockLoad = true;
        let url_w_params = this.props.url + this.props.url_paramaters(state, props, this.page_num);
        customFetch(url_w_params,
            (data: any) => {
                let row_data = this.extractRowData(data, this.props.row_selector);

                if (this.props.additonal_row_selector.length > 0) {
                    this.additional_row_data = this.additional_row_data.concat(this.extractRowData(data, this.props.additonal_row_selector))
                }

                this.setState({
                    data_rows: this.state.data_rows.concat(row_data)
                });

                this.page_num++;
                this.blockLoad = false;
            },
            (data: any) => {

            },
            this.props.request_options(state, props)
        );
    }
}

export default DataInterface;
