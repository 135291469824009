import React from "react";
import "../../App.css";
import '../../styling/bootstrap.scss';
import baseComponent from "./baseComponent";


class Popup extends baseComponent {
    static defaultProps = {
        children: (<div/>),
        visible: false,

        colour: "primary",
        header_text: "Example Popup",
        body_text: "",
        min_width: "40vw",

        onClose: null
    }

    hide = () => {
        this.setState({visible: false, page: 0});
    };

    state = {
        page: 0,
        visible: false,
        colour: "primary",
        header_text: "Example Popup",
        body_text: "Body Text",
        min_width: "40vw",

        onClose: this.hide
    };
    props = {
        children: (<div/>),
        visible: false,

        colour: "primary",
        header_text: "Example Popup",
        body_text: "Body Text",
        min_width: "40vw",

        onClose: this.hide
    }

    componentDidMount() {
        this.setState(this.props);
    }

    show = () => {
        this.setState({visible: true, page: 0});
    };

    body_html() {
        return (<div/>)
    }

    render() {
        return (
            <div className="popup" id="popup_registration" style={{display: this.state.visible ? "block" : "none"}}>
                <div className="center">
                    <div className={"card border-" + this.state.colour + " mb-3"}>
                        <div className="card-header">{this.state.header_text}</div>
                        <div className="card-body">
                            {this.state.body_text}
                            {this.props.children}
                            {this.body_html()}
                            <button className="btn btn-danger right"
                                    onClick={this.state.onClose == null ? this.hide : this.state.onClose}
                                    type="button">Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;