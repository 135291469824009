import baseComponent from "../general/baseComponent";
import React from "react";
import doFetch from "../../scripts/fetch";

class JobDataView extends baseComponent {
    state = {
        job_data: null,
        id: null
    }

    selfLoadData(id: number) {
        doFetch("crud/jobs/detail?id=" + id, "get",
            (data: any) => {
                this.setState({job_data: data});
            },
            (data: any) => {
                console.warn("Failed to load job detail! Id: " + id);
            }
        );
    }

    render() {
        let addr = "";
        let contact = "";

        if (this.state.job_data) {
            let _data = this.state.job_data;
            addr = (_data["VENUE_ADDRESS"] as string).length > 0 ? _data["VENUE_ADDRESS"] : _data["ADDRESS"];
            addr = addr.replace(/\r\n|\r|\n/g, ", ");
            contact = (_data["MOBILE"] as string).length > 0 ? _data["MOBILE"] : _data["TELEPHONE"];
            contact = _data["NAME"] + " | " + contact + " | " + _data["EMAIL"];
        }

        let jobName = "";
        let fds = "";
        let lds = "";
        let live = "";
        let outoflive = "";

        if (this.state.job_data != null) {
            let s = this.state.job_data as any;
            jobName = s["JOB_NAME"];
            fds = s["OUT_DATE"]?.split(" ")[0];
            lds = s["RETURN_DATE"]?.split(" ")[0];
            live = s["JOB_DATE"]?.split(" ")[0];
            outoflive = s["JOB_END"]?.split(" ")[0];
        }

        let id = this.state.id;

        return (<div>
            <div className="container" style={{minWidth: "100%"}}>
                <div className="row">
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_id">Name - <a
                            href={"https://myhirehop.com/job.php?id=" + id} target="_blank" rel="noreferrer">View on
                            HH (ID: {id})</a></label>
                        <input className="form-control" id="job_id" placeholder="Name" readOnly type="text"
                               value={jobName}/>
                    </div>
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_name">Customer</label>
                        <input className="form-control" id="job_name" placeholder="Customer" readOnly type="text"
                               value={this.state.job_data ? this.state.job_data["NAME"] : ""}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_in">In (FDS)</label>
                        <input className="form-control" id="job_in" placeholder="N/A" readOnly type="text"
                               value={fds}/>
                    </div>
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_live">Live</label>
                        <input className="form-control" id="job_live" placeholder="N/A" readOnly type="text"
                               value={live}/>
                    </div>
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_out_live">Out of live</label>
                        <input className="form-control" id="job_out_live" placeholder="N/A" readOnly type="text"
                               value={outoflive}/>
                    </div>
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_out">Out (LDS)</label>
                        <input className="form-control" id="job_out" placeholder="N/A" readOnly type="text"
                               value={lds}/>
                    </div>
                </div>
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_address">Address</label>
                        <small> May be client address if venue address was not found.</small>
                        <input className="form-control" id="job_address" placeholder="Address" readOnly type="text"
                               value={addr}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <label className="col-form-label mt-2" htmlFor="job_contact">Contact</label>
                        <input className="form-control" id="job_contact" placeholder="Contact" readOnly type="text"
                               value={contact}/>
                    </div>
                </div>
            </div>

        </div>);
    }

}

export default JobDataView;