import React from "react";
import '../../styling/bootstrap.scss'

class Card extends React.Component {
    static defaultProps = {
        centered: true,
        children: (<div/>),
        width: "90vw"
    }
    props = {
        centered: true,
        title: "Example Title",
        colour: "primary",
        children: (<div/>),
        width: ""
    }

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <div className={this.props.centered ? "center-x" : ""} style={{width: this.props.width}}>
                <div className={"card border-" + this.props.colour + " mb-3"} style={{minWidth: "100%"}}>
                    <div className="card-header">{this.props.title}</div>
                    <div className="card-body">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;