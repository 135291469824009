import DataGrid from "../data/dataGrid";

class JobsSearch extends DataGrid {
    state = {
        data: {rows: [{Id: "0", Name: "Hi"}]},
        pages: [],
        page: 0,
        selectedIdx: -1,

        job_search: "",
        job_active_today: true,
        job_enquiry: true,
        job_provisional: true,
        job_confirmed: true,
        job_prepped: true,
        job_part_dispatched: true,
        job_dispatched: true,
        job_returned_incomplete: true,
        job_returned: true,
        job_requires_attention: true,
        job_cancelled: false,
        job_not_happening: false,
        job_completed: false,
        job_recent: false,

        search_mode: 0
    }

    get_statuses(state: any, props: any) {
        let status = "";

        status += state.job_enquiry ? "0," : "";
        status += state.job_provisional ? "1," : "";
        status += state.job_confirmed ? "2," : "";
        status += state.job_prepped ? "3," : "";
        status += state.job_part_dispatched ? "4," : "";
        status += state.job_dispatched ? "5," : "";
        status += state.job_returned_incomplete ? "6," : "";
        status += state.job_returned ? "7," : "";
        status += state.job_requires_attention ? "8," : "";
        status += state.job_cancelled ? "9," : "";
        status += state.job_not_happening ? "10," : "";
        status += state.job_completed ? "11," : "";

        status = status.substr(0, status.length - 1);

        return status;
    }

    pageParams(state: any, props: any) {
        let params = "?page=" + (state.page + 1) + "&showRecent=" + state.job_recent;
        if (state.job_search.length > 0) {
            switch (state.search_mode.toString()) {
                case "0":
                    params += "&name=" + state.job_search;
                    break;
                case "1":
                    params += "&id=" + state.job_search;
                    break;
                case "2":
                    params += "&from=" + state.job_search + "&to=" + state.job_search;
                    break;
            }
            if (state.search_mode == 2) {
            } else {
            }
        }

        if (state.job_active_today) {
            params += "&activeToday=true";
        } else {
            params += "&status=" + this.get_statuses(state, props);
        }
        return params;
    }
}

export default JobsSearch;
