import React from "react";
import Popup from "../general/popup";
import Alert from "../general/alert";
import doFetch from "../../scripts/fetch";

class HireHopLogin extends Popup {

    private readonly promptPopup: React.RefObject<Alert>;
    private readonly error: React.RefObject<Alert>;
    private readonly hh_password_ref: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.promptPopup = React.createRef<Alert>();
        this.error = React.createRef<Alert>();
        this.hh_password_ref = React.createRef();
    }

    hide = () => {
        this.setState({visible: false, page: 0});
    };

    state = {
        page: 0,
        visible: false,
        colour: "primary",
        header_text: "Example Popup",
        body_text: "Body Text",
        min_width: "40vw",
        onClose: this.hide,

        field_password: "",
        hh_email: "",
        hh_password: ""
    }

    show = () => {
        this.promptPopup.current?.changeDetails("warning", "Hire Hop Connection Inactive!", "Please enter your Field Portal password below. This will allow us to load your Hire Hop details from secure storage.", true);

        this.setState({
            colour: "primary",
            header_text: "Hire Hop Connection",
            body_text: "",
            visible: true,
            page: 0
        });
    }


    checkFieldPassword() {
        let that = this;

        doFetch("auth/hirehop/grantuse", "post",
            (data: any) => {
                that.hide();
                document.location.reload();
            },
            (data: any) => {
            console.log(data);
                if (data["status"] == 401) {
                    that.promptPopup.current?.changeDetails("danger", "No Valid Hire Hop Details!", "Please enter your Hire Hop login details below. These will be stored securely to allow changes to be made on Hire Hop on your behalf.", true);
                    that.setState({page: 1});
                } else {
                    that.error.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
                }
            },
            {
                "userpassword": that.state.field_password
            }
        );
    }

    grantHireHopDetails() {
        let that = this;
        doFetch("auth/hirehop/login", "post",
            (data: any) => {
                that.hide();
                document.location.reload();
            },
            (data: any) => {
                that.error.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
            },
            {
                "email": this.state.hh_email,
                "hhpassword": this.state.hh_password,
                "userpassword": this.state.field_password
            }
        );
    }

    loginClicked() {
        switch (this.state.page) {
            case 0:
                this.checkFieldPassword();
                break;

            case 1:
                this.grantHireHopDetails();
                break;
        }
    }

    deleteClicked() {
        var that = this;
        doFetch("auth/hirehop/remove", "delete",
            (data: any) => {
                that.setState({
                    page: 1
                });
            },
            (data: any) => {
                that.error.current?.changeDetails("warning", "An Error Occurred", data["detail"], true);
            }
        );
    }

    body_html() {
        let that = this;
        let input_fields = this.state.page === 0 ?
            (<div className='form-group'>
                <label className='col-form-label mt-2'>Please Enter Your <strong>Field Portal</strong> Password</label>
                <input className='form-control' placeholder='Password' type='password'
                       value={this.state.field_password} name="field_password"
                       onChange={(event => this.onValChange(event))}
                       onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, () => {
                           that.loginClicked();
                       }))}/>
            </div>)
            :
            (<div className='form-group'>
                <label className='col-form-label mt-2'>Please Enter Your <strong>Hire Hop</strong> Email</label>
                <input className='form-control' placeholder='Email' type='text'
                       value={this.state.hh_email} name="hh_email" onChange={(event => this.onValChange(event))}
                       onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.hh_password_ref, null))}/>
                <label className='col-form-label mt-2'><strong>Hire Hop</strong> Password</label>
                <input className='form-control' placeholder='Password' type='password'
                       value={this.state.hh_password} ref={this.hh_password_ref} name="hh_password"
                       onChange={(event => this.onValChange(event))}
                       onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, () => {
                           that.loginClicked();
                       }))}/>
            </div>);


        return (<div>
            <Alert ref={this.promptPopup}/>
            <form>
                {input_fields}
                <Alert ref={this.error}/>
                <button className="btn btn-success right" onClick={(event => {
                    this.loginClicked();
                })} type="button">Continue
                </button>
                <button className="btn btn-warning right" onClick={(event => {
                    this.deleteClicked();
                })} type="button" hidden={this.state.page != 0}>Reset HH
                </button>
            </form>
        </div>)
    }
}

export default HireHopLogin;
