import Page from "../../page";
import React from "react";
import DataGrid2 from "../../../components/data/dataGrid2";
import DataView from "../../../components/data/dataView";
import Card from "../../../components/general/card";
import DataRow from "../../../components/data/dataRow";
import DataManager from "../../../components/data/dataManager";

class Configurables extends Page {
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_view = React.createRef<DataView>();
    }

    renderAlways() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="../home">Home</a></li>
                <li className="breadcrumb-item"><a href="../admin">Admin</a></li>
                <li className="breadcrumb-item">Configurables</li>
            </ol>
            <Card colour="info" title="Configurables">
                <div>
                    <DataGrid2 ref={this.data_grid} url="crud/configurables/search"
                               row_selector={[]}
                               header_titles={["Key", "Value", "Created By"]}
                               header_sizes={[3, 3, 6]}
                               cell_body_extractors={[
                                   (d: any) => (<p>{d["key"]}</p>),
                                   (d: any) => (<p>{d["value"]}</p>),
                                   (d: any) => (<p>{d["createdByEmail"]}</p>)
                               ]}
                               row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                                   this.data_view.current?.data_grid_row_selected(index, row_data)
                               }}
                    />
                    <DataManager data_grid={this.data_grid} action_url="crud/configurables"
                                 add_enabled={true} delete_enabled={false}
                                 data_view={this.data_view}
                                 update_data_key={[]}
                                 default_obj={{
                                     key: "",
                                     value: ""
                                 }}
                    />
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Key", "Value", "Created By"]}
                              field_value_selector={[["key"], ["value"], ["createdByEmail"]]}
                              field_readonly={[false, false, true]}
                              field_types={["string", "string", "string"]}
                    />
                </div>
            </Card>
        </div>);
    }

}

export default Configurables;
