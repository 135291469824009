import Page from "../../page";
import React from "react";
import DataGrid2 from "../../../components/data/dataGrid2";
import DataView from "../../../components/data/dataView";
import Card from "../../../components/general/card";
import DataRow from "../../../components/data/dataRow";
import DataManager from "../../../components/data/dataManager";

class GeneralAnnouncements extends Page {
    state = {
        hh_granted: false,
        level: -1,
        require_admin: true
    }
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_view = React.createRef<DataView>();
    }

    renderAlways() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="../home">Home</a></li>
                <li className="breadcrumb-item"><a href="../admin">Admin</a></li>
                <li className="breadcrumb-item">Logs</li>
            </ol>
            <Card colour="info" title="Logs">
                <div>
                    <div>
                        <select id="level" name="level" onChange={(event => {
                            this.onValChange(event);
                            this.data_grid.current?.reload();
                        })}
                                value={this.state.level}>
                            <option value="-1">All</option>
                            <option value="0">Important</option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                            <option value="4">General</option>
                        </select>
                    </div>
                    <DataGrid2 ref={this.data_grid} url="crud/logs/search"
                               url_paramaters={(state: Readonly<{}>, props: Readonly<{
                                   page_size: number
                               }>, page_num: number) => {
                                   return (this.state.level == -1 ? "?" : "?keys=level&values=" + this.state.level) + "&count=" + props.page_size + "&page=" + page_num;
                               }}
                               row_selector={[]} header_titles={["Happened At", "Description", "Performed By"]}
                               header_sizes={[4, 4, 4]}
                               additonal_row_selector={[]}
                               row_styler={(row_data,) => {
                                   let c = "limegreen";
                                   switch (row_data["level"]) {
                                       case 0:
                                           c = "orangered";
                                           break;
                                       case 1:
                                           c = "orange";
                                           break;
                                       case 2:
                                           c = "lightgreen";
                                           break;
                                       case 3:
                                           c = "lightblue";
                                           break;
                                       case 4:
                                           c = "gray";
                                           break;
                                   }
                                   return {backgroundColor: c}
                               }}
                               cell_body_extractors={[
                                   (d: any) => {
                                       return (<div>{d["happenedAt"].replace("T", " @ ")}</div>)
                                   },
                                   (d: any) => {
                                       return (<div>{d["description"]}</div>)
                                   },
                                   (d: any) => {
                                       return (
                                           <div>{d["performedByEmail"]}</div>)
                                   }
                               ]}
                               row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                                   this.data_view.current?.data_grid_row_selected(index, row_data)
                               }}
                    />
                    <DataManager data_grid={this.data_grid} action_url="crud/logs"
                                 add_enabled={false} update_enabled={false} delete_enabled={false}
                                 data_view={this.data_view}
                                 update_data_key={[]}
                                 default_obj={{}}
                    />
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Description"]}
                              field_value_selector={[["description"]]}
                              field_readonly={[true]}
                              field_types={["string"]}
                    />
                </div>
            </Card>
        </div>);
    }

}

export default GeneralAnnouncements;
