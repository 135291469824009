import React from "react";
import Page from "../../page";
import '../../../styling/bootstrap.scss'
import Card from "../../../components/general/card";
import Alert from "../../../components/general/alert";
import JobsSearch from "../../../components/job/JobsSearch";
import doFetch from "../../../scripts/fetch";
import JobDataView from "../../../components/job/JobDataView";

class JobManager extends Page {
    state = {
        hh_granted: false,
        require_admin: false,
        job_search: "",

        job_active_today: true,
        job_enquiry: true,
        job_provisional: true,
        job_confirmed: true,
        job_prepped: true,
        job_part_dispatched: true,
        job_dispatched: true,
        job_returned_incomplete: true,
        job_returned: true,
        job_requires_attention: true,
        job_cancelled: false,
        job_not_happening: false,
        job_completed: false,
        job_recent: false,

        selected_job_data: null,
        additional_selected_job_data: null,

        result_count: 0,
        search_mode: 0
    }

    private job_view_error: React.RefObject<Alert>;
    private readonly job_selected_error: React.RefObject<Alert>;
    private readonly job_search: React.RefObject<JobsSearch>;
    private readonly job_data_ref: React.RefObject<JobDataView>;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.job_view_error = React.createRef();
        this.job_selected_error = React.createRef();
        this.job_search = React.createRef();
        this.job_data_ref = React.createRef();
    }

    onHHLogin() {
        this.setState({hh_granted: true});
        this.job_search.current?.selfLoadPage();
    }

    componentDidMount() {
        this.requireHH();
    }

    status_filters() {
        return (
            <div className="container" id="status_filters">
                <div className="row">
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_enquiry" type="checkbox"
                               checked={this.state.job_enquiry} name="job_enquiry"
                               onChange={(event => this.onCheckboxChange(event))}
                        />
                        <label className="form-check-label" htmlFor="job_enquiry">Enquiry</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_provisional" type="checkbox"
                               checked={this.state.job_provisional} name="job_provisional"
                               onChange={(event => this.onCheckboxChange(event))}
                        />
                        <label className="form-check-label"
                               htmlFor="job_provisional">Provisional</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_confirmed" type="checkbox"
                               checked={this.state.job_confirmed} name="job_confirmed"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label"
                               htmlFor="job_confirmed">Confirmed</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_prepped" type="checkbox"
                               checked={this.state.job_prepped} name="job_prepped"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_prepped">Prepped</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_part_dispatched" type="checkbox"
                               checked={this.state.job_part_dispatched} name="job_part_dispatched"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_part_dispatched">Part
                            Dispatched</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_dispatched" type="checkbox"
                               checked={this.state.job_dispatched} name="job_dispatched"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label"
                               htmlFor="job_dispatched">Dispatched</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_returned_incomplete" type="checkbox"
                               checked={this.state.job_returned_incomplete} name="job_returned_incomplete"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_returned_incomplete">Returned
                            Incomplete</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_returned" type="checkbox"
                               checked={this.state.job_returned} name="job_returned"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_returned">Returned</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <input className="form-check-input"
                               id="job_requires_attention" type="checkbox"
                               checked={this.state.job_requires_attention} name="job_requires_attention"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_requires_attention">Requires
                            Attention</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input" id="job_cancelled"
                               type="checkbox"
                               checked={this.state.job_cancelled} name="job_cancelled"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label"
                               htmlFor="job_cancelled">Cancelled</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input" id="job_not_happening"
                               type="checkbox"
                               checked={this.state.job_not_happening} name="job_not_happening"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label" htmlFor="job_not_happening">Not
                            Happening</label>
                    </div>
                    <div className="col-sm">
                        <input className="form-check-input" id="job_completed"
                               type="checkbox"
                               checked={this.state.job_completed} name="job_completed"
                               onChange={(event => this.onCheckboxChange(event))}/>
                        <label className="form-check-label"
                               htmlFor="job_completed">Completed</label>
                    </div>
                </div>
            </div>);
    }

    search_changed() {
        this.job_search.current?.reload(this.state);
    }

    search_params_changed(state: any) {
        this.job_search.current?.reload(state);
    }

    onCheckboxChange(event: any) {
        this.setState({
            [event.target.name]: event.target.checked
        });
        this.search_params_changed(Object.assign(this.state, {
            [event.target.name]: event.target.checked
        }));
    }

    uncheckAll() {
        let newState = {
            job_active_today: false,
            job_enquiry: false,
            job_provisional: false,
            job_confirmed: false,
            job_prepped: false,
            job_part_dispatched: false,
            job_dispatched: false,
            job_returned_incomplete: false,
            job_returned: false,
            job_requires_attention: false,
            job_cancelled: false,
            job_not_happening: false,
            job_completed: false,
            job_recent: false
        };

        this.setState(newState);
        this.search_params_changed(newState);
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{
        search_mode: any,
        job_active_today: boolean
    }>, prevContext: any) {
        if (this.state.job_active_today && prevState.search_mode == 2) {
            this.setState({search_mode: 0, job_active_today: true});
        } else if (prevState.job_active_today && this.state.search_mode == 2) {
            this.setState({job_active_today: false});
        }
    }

    jobsearch_body() {
        return (
            <div className="form-group">

                <label className="col-form-label mt-2" htmlFor="job_search" style={{marginRight: "0.5rem"}}>Search
                    by</label>
                <select id="activated_input" name="search_mode" onChange={(event => this.onValChange(event))}
                        value={this.state.search_mode}>
                    <option value="0">Job Name</option>
                    <option value="1">Job Id</option>
                    <option value="2">Active Date</option>
                </select>
                <input className="form-control" id="job_search"
                       placeholder="Job" name="job_search" onChange={(event => this.onValChange(event))}
                       type="text" onKeyPress={(e => {
                    this.onKeyCheckFocusFlow(e, null, () => {
                        this.search_changed()
                    });
                    this.job_search.current?.clear();
                })} style={{display: this.state.search_mode != 2 ? "inline-block" : "none"}}/>

                <input className="form-control" id="report_date" type="date" name="job_search"
                       value={this.state.job_search} onChange={(event => {
                    this.onValChange(event);
                })}
                       style={{display: this.state.search_mode == 2 ? "inline-block" : "none"}}/>

                {this.state.search_mode != 1 ? (
                    <div>
                        <div>
                            <input className="form-check-input" id="job_recent" type="checkbox"
                                   checked={this.state.job_recent} name="job_recent"
                                   onChange={(event => this.onCheckboxChange(event))}/>
                            <label className="form-check-label"
                                   htmlFor="job_recent">Show Recent Jobs Containing <strong>Your
                                Reports</strong>.</label>
                        </div>
                        {this.state.job_recent ? null : (
                            <div>
                                <input className="form-check-input" id="job_active_today" type="checkbox"
                                       checked={this.state.job_active_today} name="job_active_today"
                                       onChange={(event => this.onCheckboxChange(event))}/>
                                <label className="form-check-label"
                                       htmlFor="job_active_today">Show <strong>Only</strong> Jobs Active
                                    Today. Untick for more options.</label>
                            </div>
                        )}
                        {this.state.job_active_today || this.state.job_recent ? null : this.status_filters()}
                    </div>) : (<div></div>)}
                <button type="button" className="btn btn-success" onClick={() => {
                    this.search_changed()
                }}>Search
                </button>
                <button type="button" className="btn btn-info" onClick={() => {
                    this.uncheckAll()
                }}>Uncheck All
                </button>
            </div>);
    }

    loadAdditionalJobData(job_data: any) {
        let id = this.gettId(job_data);

        doFetch("crud/jobs/detail?id=" + id, "get",
            (data: any) => {
                this.setState({additional_selected_job_data: data});
                this.job_data_ref.current?.setState({job_data: data, id: id});
            },
            (data: any) => {
                console.warn("Failed to load job detail! Id: " + id);
            }
        );
    }

    rowSelected(row: any) {
        this.setState({selected_job_data: row});
        this.loadAdditionalJobData(row);
    }

    gettId(data_row: any) {
        return this.state.job_recent || (this.state.search_mode == 1 && this.state.job_search.length > 0) ? data_row["ID"] : data_row["NUMBER"];
        ;
    }

    gotoJobAssets() {
        if (this.state.selected_job_data != null) {
            let id = this.gettId(this.state.selected_job_data);
            document.location.href = "./jobassets?id=" + id;
        } else {
            this.job_selected_error.current?.changeDetails("danger", "None Selected!", "Please select a job first.", true);
        }
    }

    gotoJobReports() {
        if (this.state.selected_job_data != null) {
            let id = this.gettId(this.state.selected_job_data);
            document.location.href = "./jobreport?id=" + id;
        } else {
            this.job_selected_error.current?.changeDetails("danger", "None Selected!", "Please select a job first.", true);
        }
    }

    loadedAPage(data: any) {
        this.setState({result_count: data["records"]});
    }

    renderHHRequired() {
        return (
            <div>
                <ol className="custom_breadcrumb center-x">
                    <li className="breadcrumb-item"><a href="./home">Home</a></li>
                    <li className="breadcrumb-item">Job Manager</li>
                </ol>
                <Card colour="warning" title="Job Search">
                    <div>
                        {this.jobsearch_body()}
                        <p>Results: {this.state.result_count}</p>
                        <JobsSearch ref={this.job_search}
                                    collumnNames={["Id", "Name", "Customer", "Region", "In (FDS)", "Live", "Out of live", "Out (LDS)"]}
                                    collumnWidth={[1, 3, 2, 2, 1, 1, 1, 1]}
                                    dataSelectors={
                                        [
                                            (d => {
                                                let id = this.gettId(d);
                                                return (
                                                    <a href={"https://myhirehop.com/job.php?id=" + id} target="_blank"
                                                       rel="noreferrer">{id}</a>);
                                            }),
                                            (d => <p>{d["JOB_NAME"]}</p>),
                                            (d => <p>{d["COMPANY"]}</p>),
                                            (d => <p>{d["DEPOT"]}</p>),
                                            (d => <p>{d["OUT_DATE"]?.split(" ")[0]}</p>),
                                            (d => <p>{d["JOB_DATE"]?.split(" ")[0]}</p>),
                                            (d => <p>{d["JOB_END"]?.split(" ")[0]}</p>),
                                            (d => <p>{d["RETURN_DATE"]?.split(" ")[0]}</p>)
                                        ]
                                    }
                                    url="crud/jobs/search" rowDataSelector={(d => d["results"])}
                                    onRowSelected={(row => {
                                        this.rowSelected(row)
                                    })}
                                    onPageLoadFinished={(data => {
                                        this.loadedAPage(data);
                                    })}
                                    pageSize={40}
                        />
                    </div>
                </Card>
                <Card colour="info" title="Selected Job">
                    <div>
                        <JobDataView ref={this.job_data_ref}/>
                        <br/>
                        <Alert ref={this.job_selected_error}/>
                        <div className="container-fluid">
                            <div className="container" style={{minWidth: "100%"}}>
                                <div className="row center-text">
                                    <div className="col-sm">
                                        <button className="btn btn-primary" onClick={(event => {
                                            this.gotoJobAssets()
                                        })} type="button">Asset Manager
                                        </button>
                                    </div>
                                    <div className="col-sm">
                                        <button className="btn btn-primary" onClick={(event => {
                                            this.gotoJobReports()
                                        })} type="button">Job Reports
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export default JobManager;
