import Popup from "../../../components/general/popup";
import React from "react";
import DataGrid2 from "../../../components/data/dataGrid2";
import embeddableText from "../../../scripts/embeddableText";
import Page from "../../page";
import Home from "../home";
import doFetch from "../../../scripts/fetch";
import DataRow from "../../../components/data/dataRow";

class SafetyAnnouncements extends Popup {
    private readonly view_ref: React.RefObject<DataGrid2>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.view_ref = React.createRef();
    }

    _show() {
        this.show();
        this.view_ref.current?.reload();
    }

    onClose() {
        this.hide();
        this.view_ref.current?.reload();
    }

    body_html() {
        let that = this;
        return (<DataGrid2 ref={this.view_ref} url="crud/announcements/search"
                           row_selector={[]}
                           header_titles={["Description", "Created", "Priority", "Your Action"]}
                           header_sizes={[6, 2, 2, 2]}
                           cell_body_extractors={[
                               (d: any) => (<p>{embeddableText(d["description"])}</p>),
                               (d: any) => (<p>{d["createdAt"].replace("T", " ")}</p>),
                               (d: any) => (
                                   <p>{d["priority"] == 0 ? "High" : (d["priority"] == 1 ? "Med" : "Low")}</p>),
                               (d: any) => (<p>{d["action"]}</p>)
                           ]}
                           row_styler={(rowData: any) => {
                               return {fontWeight: (rowData["hasRead"] ? "" : "bold")}
                           }}
                           row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                               doFetch("crud/announcements/ihaveread?aid=" + row_data["id"], "post",
                                   (d: any) => {
                                       (Page.cur_page as Home).loadUnread();
                                       row_element.current?.setState({style: {fontWeight: ""}, useStateStyle: true})
                                   },
                                   (d: any) => {
                                   })
                           }}
        />);
    }
}

export default SafetyAnnouncements;