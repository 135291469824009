import React from "react";
import Page from "../page";
import NavGridItem from "../../components/shared/navGridItem";
import '../../styling/shared.scss'
import SafetyAnnouncements from "./home/safety_announcements";
import doFetch from "../../scripts/fetch";
import DataGrid2 from "../../components/data/dataGrid2";
import Card from "../../components/general/card";
import embeddableText from "../../scripts/embeddableText";

class Home extends Page {
    state = {
        hh_granted: false,
        unread: 0,
        require_admin: false
    }
    private readonly safety_ann_popup: React.RefObject<SafetyAnnouncements>;
    private readonly general_grid: React.RefObject<DataGrid2>;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.safety_ann_popup = React.createRef();
        this.general_grid = React.createRef();
    }

    componentDidMount() {
        this.loadUnread();
    }

    loadUnread() {
        doFetch("crud/announcements/unread", "post",
            (d: any) => {
                this.setState({unread: d["unread"]})
            },
            (d: any) => {
            }
        );
    }

    renderAlways() {
        return (
            <div>
                <div className="container navgrid">
                    <div className="row">
                        <NavGridItem colour="primary" goto="./jobmanager"><p>Job Manager</p></NavGridItem>
                        <NavGridItem colour="primary" onClick={() => {
                            this.safety_ann_popup.current?._show();
                        }}><p>Safety Announcements (<a>{this.state.unread}</a>)</p></NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="info" newTab={true}
                                     goto="https://pmy.sharepoint.com/sites/FieldPortal/SitePages/Standard%20Operating%20Procedures.aspx">
                            <p>Standard Operating
                                Procedures</p>
                        </NavGridItem>
                        <NavGridItem colour="info" newTab={true}
                                     goto="mailto:helpdesk@pmygroup.com?subject=DEFECT%20REPORT&body=Defect%20Report">
                            <p>Defect Reporting</p>
                        </NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="info" newTab={true}
                                     goto="https://pmy.sharepoint.com/sites/FieldPortal/SitePages/Contact%20List.aspx">
                            <p>Contact List</p>
                        </NavGridItem>
                        <NavGridItem colour="info" newTab={true} goto="https://pmy-group.eu.itglue.com/login">
                            <p>Technical Knowledge
                                Base</p>
                        </NavGridItem>
                    </div>
                    <div className="row">
                        <NavGridItem colour="info" newTab={true}
                                     goto="https://elearning.pmy.cloud/login/index.php"><p>PMY E-Learning</p>
                        </NavGridItem>
                        <NavGridItem colour="warning" goto="./admin" disabled={localStorage.getItem("isAdmin") == "false"}><p>Admin</p></NavGridItem>
                    </div>

                </div>
                <SafetyAnnouncements header_text="Safety Announcements"
                                     ref={this.safety_ann_popup}/>

                <Card colour="primary" title="General Announcements" width="96vw">
                    <div>
                        <DataGrid2 ref={this.general_grid} url="crud/generalupdates/search"
                                   row_selector={[]} header_titles={["Created At", "Description", "Creator"]}
                                   header_sizes={[2, 8, 2]}
                                   additonal_row_selector={[]}
                                   cell_body_extractors={[
                                       (d: any) => {
                                           return (<div>{d["createdAt"].replace("T", " @ ")}</div>)
                                       },
                                       (d: any) => {
                                           return (<div>{embeddableText(d["body"])}</div>)
                                       },
                                       (d: any) => {
                                           return (
                                               <div>{d["createdBy"]}</div>)
                                       }
                                   ]}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

export default Home;
