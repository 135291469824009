import React from "react";
import Popup from "../general/popup";
import HireHopLogin from "./HireHopLogin";
import doFetch from "../../scripts/fetch";

class LoginChecks extends Popup {
    props = {
        hh_login_popup: React.createRef<HireHopLogin>(),

        children: (<div/>),
        visible: false,

        colour: "primary",
        header_text: "Example Popup",
        body_text: "Body Text",
        min_width: "40vw",

        onClose: this.hide
    }

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    checkHHLogin(success: any, fail: any) {
        doFetch("auth/hirehop/check", "get",
            (data: any) => {
                success();
            },
            (data: any) => {
                fail();
            }
        );
    };

    checkFieldLogin(success: any, fail: any) {
        doFetch("auth/user/check", "get",
            (data: any) => {
                success();
            },
            (data: any) => {
                fail();
            }
        )
    };

    checkLogins(successField: any, successHH: any, failField: any, failHH: any) {
        let that = this;
        that.checkFieldLogin(
            function () {
                that.checkHHLogin(successHH, failHH);
                successField();
            },
            function () {
                failField();
            }
        )
    }

    body_html() {
        return (<div/>);
    };
}

export default LoginChecks;