import React from "react";
import ResourceView from "./ResourceView";
import baseComponent from "../general/baseComponent";
import DataGrid from "../data/dataGrid";
import Alert from "../general/alert";
import doFetch from "../../scripts/fetch";

class ResourceManager extends baseComponent {
    state = {
        resource: "",
        role: "",
        start: "",
        end: "",

        id: 0,
        resource_data: {},
        inputs_readonly: true,
    }

    private readonly resource_ref: React.RefObject<DataGrid>;
    private readonly role_ref: React.RefObject<any>;
    private readonly error_ref: React.RefObject<Alert>;

    constructor(props: any) {
        super(props);
        //this.props = props;
        this.resource_ref = React.createRef();
        this.role_ref = React.createRef();
        this.error_ref = React.createRef();
    }

    onHHLogin() {
        this.resource_ref.current?.selfLoadPage();
    }

    clear() {
        this.setState({
            resource: "",
            role: "",
            start: "",
            end: ""
        })
    }

    newResource() {
        let that = this;

        doFetch("crud/resources", "post",
            (data: any) => {
                that.resource_ref.current?.addRowToTop(data);
                that.error_ref.current?.changeDetails("success", "Resource Created!", "Your Resource has been created!", true);
                that.clear();
            },
            (data: any) => {
                that.error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
            },
            {},
            {
                role: this.state.role,
                resource1: this.state.resource,
                strstart: this.state.start,
                strend: this.state.end,
                reportId: that.state.id
            });
    }

    saveResource() {
        let that = this;
        if (this.state.resource_data.hasOwnProperty("id")) {
            doFetch("crud/resources/" + (that.state.resource_data as any)["id"], "post",
                (data: any) => {
                    let c = that.resource_ref.current;
                    if (c) {
                        c.updateSelectedRow(data);
                        that.error_ref.current?.changeDetails("success", "Resource Saved!", "Your Resource has been saved!", true);
                    }
                },
                (data: any) => {
                    this.error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
                },
                {},
                {
                    role: this.state.role,
                    resource1: this.state.resource,
                    strstart: this.state.start,
                    strend: this.state.end
                });
        } else {
            that.error_ref.current?.changeDetails("warning", "None Selected!", "Please select a report first!", true);
        }
    }

    deleteResource() {
        let that = this;
        if (this.state.resource_data.hasOwnProperty("id")) {
            doFetch("crud/resources/" + (that.state.resource_data as any)["id"], "delete",
                (data: any) => {
                    let c = that.resource_ref.current;
                    if (c) {
                        that.error_ref.current?.changeDetails("success", "Report Deleted!", "Your Resource has been deleted!", true);
                        that.resource_ref.current?.reload(that.resource_ref.current);
                        that.setState({
                            resource: "",
                            role: "",
                            start: "",
                            end: ""
                        });
                    }
                },
                (data: any) => {
                    that.error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
                });
        } else {
            that.error_ref.current?.changeDetails("warning", "None Selected!", "Please select a report first!", true);
        }
    }

    resourceRowSelected(data: any) {
        let startSeg = data["start"].split(":");
        let endSeg = data["end"].split(":");
        this.setState({
            resource: data["resource1"],
            role: data["role"],
            start: this.padToTwoInt(startSeg[0]) + ":" + this.padToTwoInt(startSeg[1]),
            end: this.padToTwoInt(endSeg[0]) + ":" + this.padToTwoInt(endSeg[1]),
            resource_data: data,
            inputs_readonly: false
        })
    }

    render() {
        let that = this;
        return (
            <div>
                <h4>Resources</h4>
                <ResourceView collumnNames={["Resource", "Role", "Start (24 Hr)", "End (24 Hr)"]}
                              collumnWidth={[4, 4, 2, 2]}
                              rowDataSelector={(d: any) => d} dataSelectors={[
                    (d => d["resource1"]),
                    (d => d["role"]),
                    (d => {
                        let seg = d["start"].split(":");
                        return that.padToTwoInt(seg[0]) + ":" + that.padToTwoInt(seg[1]);
                    }),
                    (d => {
                        let seg = d["end"].split(":");
                        return that.padToTwoInt(seg[0]) + ":" + that.padToTwoInt(seg[1]);
                    }),
                ]} url="crud/resources/search" ref={this.resource_ref}
                              onRowSelected={(data => {
                                  this.resourceRowSelected(data)
                              })}/>
                <div>
                    <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <input className="form-control" placeholder="Name"
                               type="text" name="resource"
                               value={this.state.resource} onChange={(event => this.onValChange(event))}
                               onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.role_ref, null))}
                               readOnly={this.state.inputs_readonly}/>

                        <label className="col-form-label">Role (Site Lead, Cable Engineer, Technical Lead, Project
                            Manager)</label>
                        <input className="form-control" placeholder="Role"
                               type="text" name="role" ref={this.role_ref}
                               value={this.state.role} onChange={(event => this.onValChange(event))}
                               readOnly={this.state.inputs_readonly}/>

                        <label className="col-form-label">Start (24 Hr)</label>
                        <input className="form-control" type="time" name="start"
                               value={this.state.start} onChange={(event => this.onValChange(event))}
                               readOnly={this.state.inputs_readonly}/>

                        <label className="col-form-label">End (24 Hr)</label>
                        <input className="form-control" type="time" name="end"
                               value={this.state.end} onChange={(event => this.onValChange(event))}
                               readOnly={this.state.inputs_readonly}/>
                    </div>
                    <Alert ref={this.error_ref}/>
                    <div>
                        <button className="btn btn-success" onClick={(event => {
                            this.newResource()
                        })} type="button">Add
                        </button>
                        <button className="btn btn-warning" onClick={(event => {
                            this.saveResource()
                        })} type="button">Update/Save
                        </button>
                        <button className="btn btn-danger" id="deleteBtn" onClick={(event => {
                            this.deleteResource()
                        })}
                                type="button">Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    reload(state: any) {
        this.resource_ref.current?.reload(state);
    }
}

export default ResourceManager;