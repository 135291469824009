import React from "react";
import Popup from "../../components/general/popup";
import Alert from "../../components/general/alert";
import doFetch from "../../scripts/fetch";

class Recovery extends Popup {
    private readonly rec_password_ref: React.RefObject<any>;
    private readonly rec_code_ref: React.RefObject<any>;
    private readonly rec_error: React.RefObject<Alert>;
    private readonly rec_complete: React.RefObject<Alert>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.state.colour = "warning";
        this.state.header_text = "Recover Account";
        this.state.body_text = "";

        this.state.page = 0;
        this.rec_password_ref = React.createRef();
        this.rec_code_ref = React.createRef();
        this.rec_error = React.createRef();
        this.rec_complete = React.createRef();
    }

    completeRecovery() {
        let that = this;

        doFetch("user/password/completerecovery", "post",
            (data: any) => {
                that.rec_complete.current?.changeDetails("success", "Recovery Completed!", "Your Account Password Has Been Recovered! If you were logged into Hire Hop, you have been signed out.", true)
                that.setState({page: 2});
            },
            (data: any) => {
                that.rec_error.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
            },
            {
                "email": this.state.email,
                "code": this.state.code,
                "newPassword": this.state.password
            }
        );
    }

    startRecovery() {
        doFetch("user/password/recover", "post",
            (data: any) => {
                this.rec_error.current?.changeDetails("success", "Great Success", "Recovery Email Sent", true);
                this.setState({page: 1});
            },
            (data: any) => {
                this.rec_error.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
            },
            {
                'email': this.state.email
            }
        );
    }

    recoverClicked() {
        switch (this.state.page) {
            case 0:
                this.startRecovery();
                break;
            case 1:
                this.completeRecovery();
                break;
        }
    }

    hide = () => {
        this.setState({visible: false, page: 0});
    }

    state = {
        email: "",
        password: "",
        code: "",

        page: 0,
        visible: false,
        colour: "danger",
        header_text: "Password Recovery",
        body_text: "",
        body_html: this.body_html,
        min_width: "40vw",
        onClose: this.hide
    }

    body_html() {
        let secondaryRecovery = null;
        if (this.state.page === 1) secondaryRecovery = (
            <div id="recovery_code_div">
                <div className="alert alert-info">
                    <strong>Recovery Requested!</strong> <a>If the email is valid, you shall
                    receive a code
                    which should be submitted below.</a>
                </div>

                <label className="col-form-label mt-2" htmlFor="recovery_code">Recovery
                    Code</label>
                <input className="form-control" id="recovery_code" name="code" placeholder="Code"
                       type="text" ref={this.rec_code_ref} value={this.state.code}
                       onChange={(event => this.onValChange(event))}
                       onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.rec_password_ref, null))}/>

                <label className="col-form-label mt-2" htmlFor="recovery_new_password">New
                    Password</label>
                <input className="form-control" id="recovery_new_password"
                       placeholder="Password" name="password"
                       type="password" ref={this.rec_password_ref} value={this.state.password}
                       onChange={(event => this.onValChange(event))}
                       onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, () => this.recoverClicked()))}/>
            </div>);

        let first_msg = this.state.page === 0 ? (
            <div className="alert alert-info">
                <strong>Recover your Account!</strong> <a>Input your account email below and Submit. This will send you
                a recovery code.</a>
            </div>) : (<div/>);

        let main_body = null;
        if (this.state.page < 2) {
            main_body = (
                <div className="form-group">
                    {first_msg}
                    <label className="col-form-label mt-2" htmlFor="recovery_email">Field Portal Email</label>
                    <input className="form-control" id="recovery_email" placeholder="Email" type="text" name="email"
                           readOnly={this.state.page !== 0} value={this.state.email}
                           onChange={(event => this.onValChange(event))}
                           onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.rec_code_ref, () => this.recoverClicked()))}/>
                    {secondaryRecovery}
                    <Alert ref={this.rec_error}/>
                    <div>
                        <div id="recovery_error"/>
                        <button className="btn btn-success right" onClick={() => this.recoverClicked()}
                                type="button">Submit
                        </button>
                    </div>
                </div>);
        }

        return (
            <form>
                {main_body}
                <Alert ref={this.rec_complete}/>
            </form>
        );
    }
}

export default Recovery;