import React from 'react';
import './footer.scss'
import '../../styling/bootstrap.scss';

class Footer extends React.Component {
    render() {
        return (
            <div className="bottombar bg-dark container-fluid text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <a href="https://www.pmygroup.com/">Product of © PMY Group</a>
                        </div>
                        <div className="col-sm">
                            <a href="tos.html">Terms Of Use</a>
                        </div>
                        <div className="col-sm">
                            <a href="https://github.com/Etherlive">Codebase - Github</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
