import React from "react";
import baseComponent from "../general/baseComponent";
import justlogs from "../../scripts/justlogs";

class ErrorReports extends baseComponent {
    state = {
        open: false,
        submitted: false,
        wrong: "",
        expected: ""
    }

    private readonly expected_ref: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.expected_ref = React.createRef();
    }

    submitReport() {
        justlogs.addLog("An Error Report Was Submitted - " + localStorage.getItem("uid"), this.state.wrong + "\r\n" + this.state.expected, "field-portal-reports");
        this.setState({submitted: true});
    }

    render() {
        if (this.state.open) {
            if (this.state.submitted) {
                return (
                    <div style={{
                        position: "fixed",
                        bottom: 0,
                        backgroundColor: "lightgray",
                        padding: "10px",
                        width: "500px",
                        border: "black 1px solid"
                    }}>
                        <p>Error Report Has Been Submitted. Thank you.</p>
                        <button className="btn btn-warning right"
                                onClick={() => this.setState({open: false, submitted: false})}
                                type="button">Close
                        </button>
                    </div>)
            } else {
                return (
                    <div style={{
                        position: "fixed",
                        bottom: 0,
                        backgroundColor: "lightgray",
                        padding: "10px",
                        width: "500px",
                        border: "black 1px solid"
                    }}>
                        <h3>Report A Problem With The Site</h3>
                        <div className='form-group'>
                            <label className='col-form-label mt-2'>What Went Wrong?</label>
                            <textarea className='form-control' placeholder='What was the error?'
                                      value={this.state.wrong} name="wrong"
                                      onChange={(event => this.onValChange(event))}
                                      onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.expected_ref, null))}/>
                            <label className='col-form-label mt-2'>What Did You Expect?</label>
                            <textarea className='form-control' placeholder='What should have happened?'
                                      value={this.state.expected} ref={this.expected_ref} name="expected"
                                      onChange={(event => this.onValChange(event))}
                                      onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, () => {
                                      }))}/>

                            <button className="btn btn-success right" onClick={(event => {
                                this.submitReport();
                            })} type="button">Submit Error
                            </button>
                            <button className="btn btn-warning right" onClick={() => this.setState({open: false})}
                                    type="button">Close
                            </button>
                        </div>
                    </div>)
            }
        } else {
            return (
                <div style={{position: "fixed", bottom: "5px"}} onClick={() => this.setState({open: true})}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="orangered"
                         className="bi bi-bug-fill" viewBox="0 0 16 16">
                        <path
                            d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z"/>
                        <path
                            d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z"/>
                    </svg>
                </div>
            );
        }
    }
}

export default ErrorReports;