import React from "react";
import {What3wordsAutosuggest} from "@what3words/react-components";

class What3WordsInput extends React.Component<any, any> {
    state = {
        auto_what3Words: "",
        lat: 0,
        long: 0,
        status: ""
    }

    props = {
        name: "",
        value: "",
        onChange: (event: any) => {
        }
    }
    private apiKey = "O8M5DBI4";
    private loadedFromLocation = false;
    private w3w_input: any;

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    getLocation() {
        let that = this;
        if (!navigator.geolocation) {
            that.setState({status: "Geolocation is not supported by your browser"});
        } else {
            that.setState({status: "Locating..."});
            navigator.geolocation.getCurrentPosition((position) => {
                that.setState({
                    status: "",
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                });
                that.props.onChange({target: {name: "2", value: position.coords.latitude}});
                that.props.onChange({target: {name: "3", value: position.coords.longitude}});
                that.convertToWhat3Words();
            }, () => {
                that.setState({status: "Unable to retrieve your location"});
            });
        }
    }

    // componentDidMount() {
    //     this.getLocation();
    // }

    convertToWhat3Words() {
        let that = this;
        fetch("https://api.what3words.com/v3/convert-to-3wa?key=" + that.apiKey + "&coordinates=" + that.state.lat + "%2C" + that.state.long + "&language=en&format=json").then(
            response => {
                return response.json();
            }
        ).then(
            data => {
                that.loadedFromLocation = true;
                let w3w = "///" + data["words"];
                //that.setState({auto_what3Words: w3w});
                that.props.onChange({target: {name: that.props.name, value: w3w}});
                var event = new Event('input', {bubbles: true});
                that.w3w_input.dispatchEvent(event);
            }
        )
    }

    render() {
        return (
            <div>
                <What3wordsAutosuggest api_key={this.apiKey}>
                    <input
                        ref={(i => this.w3w_input = i)}
                        type="text"
                        name={this.props.name}
                        id={this.props.name + "input"}
                        value={this.props.value.length > 0 ? this.props.value : ""}
                        onChange={(event) => {
                            this.props.onChange(event);
                        }}
                    />
                    <button onClick={() => this.getLocation()} className="btn btn-dark" type="button"><i
                        className="material-icons">&#xe55c;</i></button>
                    <button onClick={() => window.open("https://what3words.com/" + this.props.value, '_blank')}
                            className="btn btn-dark"><i className="material-icons">&#xe55b;</i></button>
                </What3wordsAutosuggest>
            </div>
        );
    }
}


export default What3WordsInput;
