import Page from "../../page";
import DataManager from "../../../components/data/dataManager";
import DataGrid2 from "../../../components/data/dataGrid2";
import React from "react";
import Card from "../../../components/general/card";
import DataView from "../../../components/data/dataView";
import DataRow from "../../../components/data/dataRow";

class Users extends Page {
    state = {
        hh_granted: false,
        admins: 0,
        require_admin: true
    }
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_view = React.createRef<DataView>();
    }

    renderAlways() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="../home">Home</a></li>
                <li className="breadcrumb-item"><a href="../admin">Admin</a></li>
                <li className="breadcrumb-item">User Management</li>
            </ol>
            <Card colour="info" title="User Management">
                <div>
                    <div>
                        <select id="admins" name="admins" onChange={(event => {
                            this.onValChange(event);
                            this.data_grid.current?.reload();
                        })}
                                value={this.state.admins}>
                            <option value="0">All</option>
                            <option value="1">User</option>
                            <option value="2">Admin</option>
                        </select>
                    </div>
                    <DataGrid2 ref={this.data_grid} url="crud/user/search" row_selector={[]}
                               header_titles={["Id", "Last Login", "Email", "HH Email", "Admin", "Active"]}
                               header_sizes={[1, 2, 4, 3, 1, 1]}
                               url_paramaters={(state: Readonly<{}>, props: Readonly<{
                                   page_size: number
                               }>, page_num: number) => {
                                   return (this.state.admins == 0 ? "?" : (this.state.admins == 2 ? "?keys=admin&values=true" : "?keys=admin&values=false")) + "&count=" + props.page_size + "&page=" + page_num;
                               }}
                               cell_body_extractors={[
                                   (d: any) => {
                                       return d["id"]
                                   },
                                   (d: any) => {
                                       return d["lastUsed"].replace("T", " ").split(".")[0]
                                   },
                                   (d: any) => {
                                       return d["email"]
                                   },
                                   (d: any) => {
                                       return d["hireHopEmail"] ? d["email"] : "No Connection"
                                   },
                                   (d: any) => {
                                       return d["isAdmin"] ? "Y" : "N"
                                   },
                                   (d: any) => {
                                       return d["activated"] ? "Y" : "N"
                                   },
                               ]}
                               row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => this.data_view.current?.data_grid_row_selected(index, row_data)}
                               cell_styler={[() => {
                                   return {}
                               }, () => {
                                   return {}
                               }, () => {
                                   return {}
                               },
                                   (row_data, cell) => {
                                       return {backgroundColor: row_data["hireHopEmail"] ? "limegreen" : "orange"}
                                   },
                                   (row_data, cell) => {
                                       return {backgroundColor: row_data["isAdmin"] ? "deepskyblue" : "gray"}
                                   },
                                   (row_data, cell) => {
                                       return {backgroundColor: row_data["activated"] ? "limegreen" : "orangered"}
                                   }
                               ]}
                    />
                    <DataManager data_grid={this.data_grid} action_url="crud/user"
                                 add_enabled={false} delete_enabled={false}
                                 data_view={this.data_view}
                                 update_data_key={[]}
                    />
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Email", "Admin", "Activated"]}
                              field_value_selector={[["email"], ["isAdmin"], ["activated"]]}
                              field_readonly={[true, false, false]}
                              field_types={["string", "boolean", "boolean"]}
                    />
                </div>
            </Card>
        </div>)
    }
}

export default Users;
