import React from 'react';
import './navBar.scss'
import '../../styling/bootstrap.scss';
import HireHopLogin from "./HireHopLogin";
import LoginChecks from "./loginChecks";
import Page from "../../pages/page";
import doFetch from "../../scripts/fetch";


class NavBar extends React.Component {
    public static cur_navbar: NavBar;
    state = {
        hh_active: true
    }
    public need_hh = false;

    public login_checks: React.RefObject<LoginChecks>;
    private readonly hh_connect: React.RefObject<HireHopLogin>;
    private readonly connect_hh: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.hh_connect = React.createRef<HireHopLogin>();
        this.login_checks = React.createRef<LoginChecks>();
        this.connect_hh = React.createRef();
        NavBar.cur_navbar = this;
    }

    LogoutClicked() {
        doFetch("auth/user/logout", "delete",
            (data: any) => {
                console.log("Logged Out");
                localStorage.clear();
                document.location.href = "/login";
            },
            (data: any) => {
                console.warn("Failed to properly log out");
                localStorage.clear();
                document.location.href = "/login";
            }
        );
    }

    AccountClicked() {
        document.location.href = "/main/myaccount";
    }

    connectPressed() {
        this.hh_connect.current?.show();
    }

    doLoginChecks(callbackObj: Page) {
        let that = this;
        that.login_checks.current?.checkLogins(
            function () {
                callbackObj.onFieldLogin();
            },
            function () {
                callbackObj.onHHLogin();
            },
            function () {
                that.setState({hh_active: false});
                that.login_checks.current?.setState({
                    visible: true,
                    colour: "danger",
                    header_text: "No Field Portal Login!",
                    body_text: "You have no valid Field Portal Login! Please sign-in again.",
                    min_width: "40vw",

                    onClose: () => {
                        let param = document.location.search.substring(1).replace("&", "¬").replace("=", "~");
                        document.location.href = "/login?redirect=" + document.location.pathname + "&params=" + param;
                    }
                });
            },
            function () {
                that.setState({hh_active: false});
                if (that.need_hh) {
                    that.login_checks.current?.setState({
                        visible: true,
                        colour: "danger",
                        header_text: "No Hire Hop Login!",
                        body_text: "You have no valid Hire Hop Login! Please connect your account before visiting this page.",
                        min_width: "40vw",

                        onClose: () => {
                            that.hh_connect.current?.setState({
                                onClose: () => {
                                    document.location.href = "/main/home"
                                }
                            });
                            that.hh_connect.current?.show();
                            that.login_checks.current?.hide();
                        }
                    });
                }
            }
        )

        // this.login_checks.current?.requireFieldLogin();
        //
        // let that = this;
        // if (this.login_checks.current?.loggedIn) {
        //     this.login_checks.current?.checkHHLogin(() => {
        //         that.setState({hh_active: false});
        //     }, () => {
        //         that.setState({hh_active: true});
        //     });
        // }
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">PMY Field Portal (Beta)</a>
                            <div className="right">
                                <button className="btn btn-info" id="connectHireHopBtn"
                                        onClick={(event => this.connectPressed())}
                                        type="button" ref={this.connect_hh}
                                        style={{display: this.state.hh_active ? "none" : "inline-block"}}>Connect
                                    HireHop
                                </button>
                                <button className="btn btn-info" onClick={() => {
                                    window.open("https://pmy.sharepoint.com/sites/FieldPortal/SitePages/Field%20Portal%20Help.aspx", "_blank")
                                }} type="button" hidden={true}>Help
                                </button>
                                <button className="btn btn-info" onClick={this.AccountClicked} type="button">My Account
                                </button>
                                <button className="btn btn-warning" onClick={this.LogoutClicked} type="button">Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="internalBar">
                    <h5><b>INTERNAL</b></h5>
                </div>
                <HireHopLogin ref={this.hh_connect}/>
                <LoginChecks ref={this.login_checks} hh_login_popup={this.hh_connect}/>
            </div>
        );
    }
}

export default NavBar;
