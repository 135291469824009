let logUrl = true ? "https://justlogs.etherlive.com/api/" : "https://localhost:7236/api/";

class JustLogs {

    static addLog(memo: string, body: string, groupString: string = "field-portal") {
        fetch(logUrl + "logs?memo=" + memo + "&groupString=" + groupString, {
            credentials: "include",
            body: body,
            method: "POST"
        }).then(r => {
            switch (r.status) {
                case 200:
                    break;
                default:
                    break;
            }
        })
    }

    static getGrant() {
        fetch(logUrl + "grants/", {credentials: "include"}).then(r => {
            switch (r.status) {
                case 200:
                    break;
                default:
                    break;
            }
        })
    }

    static checkGrant(success: () => void, failure: () => void) {
        fetch(logUrl + "grants/check", {credentials: "include"}).then(r => {
            switch (r.status) {
                case 200:
                    success();
                    break;
                default:
                    failure();
                    break;
            }
        })
    }

    static getGrantIfNoGrant() {
        this.checkGrant(() => {
        }, this.getGrant);
    }
}

export default JustLogs;