import baseComponent from "../general/baseComponent";
import DataGrid2 from "./dataGrid2";
import React from "react";
import doFetch from "../../scripts/fetch";
import DataView from "./dataView";

class DataManager extends baseComponent {
    static defaultProps = {
        add_enabled: true,
        update_enabled: true,
        delete_enabled: true,

        update_data_key: [""],
        default_obj: {}
    }
    state = {
        delete_state: false
    }
    props = {
        data_grid: React.createRef<DataGrid2>(),
        data_view: React.createRef<DataView>(),

        action_url: "",

        add_enabled: true,
        update_enabled: true,
        delete_enabled: true,

        update_data_key: [""],
        default_obj: {}
    }

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    render() {
        return (<div>
            <button className="btn btn-success" onClick={(event => {
                this.new()
            })} type="button" style={{display: !this.props.add_enabled ? "none" : "inline-block"}}>Add
            </button>
            <button className="btn btn-warning" onClick={(event => {
                this.update()
            })} type="button" style={{display: !this.props.update_enabled ? "none" : "inline-block"}}>Update
            </button>
            <button className="btn btn-danger" id="deleteBtn" onClick={(event => {
                this.approveDelete()
            })}
                    type="button"
                    style={{display: this.state.delete_state || !this.props.delete_enabled ? "none" : "inline-block"}}>Delete
            </button>
            <div className="" id="deleteConfirm"
                 style={{
                     border: "red 2px solid",
                     padding: "5px",
                     display: this.state.delete_state ? "inline-block" : "none"
                 }}>
                <button className="btn btn-secondary" onClick={(event => {
                    this.cancelDelete()
                })} type="button">Cancel
                </button>
                <button className="btn btn-danger" onClick={(event => {
                    this.delete()
                })} type="button">Delete
                </button>
            </div>
        </div>)
    }

    public new(alt_d: any = null) {
        let that = this;
        doFetch(that.props.action_url, "POST",
            (d: any) => {
                that.props.data_grid.current?.reload();
            },
            (d: any) => {
            },
            {},
            alt_d != null ? alt_d : this.props.default_obj
        );
    }

    public update(alt_d: any = null) {
        let d = alt_d != null ? alt_d : this.props.data_view.current?.reconstructObject();
        let that = this;
        doFetch(that.props.action_url + "/" + d["id"], "POST",
            (d: any) => {
                if (alt_d == null) that.props.data_grid.current?.updateSelectedIdx(d, that.props.update_data_key);
            },
            (d: any) => {
            },
            {},
            d
        );
    }

    public delete(alt_d: any = null) {
        let d = alt_d != null ? alt_d : this.props.data_view.current?.reconstructObject();
        let that = this;
        doFetch(that.props.action_url + "/" + d["id"], "DELETE",
            (d: any) => {
                that.props.data_grid.current?.reload();
            },
            (d: any) => {
            },
            {},
            d
        );
        this.setState({
            delete_state: false
        });

    }

    private approveDelete() {
        this.setState({
            delete_state: true
        });
    }

    private cancelDelete() {
        this.setState({
            delete_state: false
        });
    }
}

export default DataManager;
