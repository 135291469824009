import React from "react";
import baseComponent from "../general/baseComponent";
import '../../styling/bootstrap.scss'
import './dataGrid.scss'
import customFetch from "../../scripts/customFetch";

class DataGrid extends baseComponent {
    static defaultProps = {
        maxHeight: "60vh",
        rowDataSelector: (d: any) => {
            return d["rows"]
        },
        idSelector: (d: any) => {
            return d["Id"]
        },
        method: "GET",
        onRowSelected: (row: any) => {
        },
        onPageLoadFinished: (pageData: any) => {
        },
        rowStyler: (rowData: any) => {
            return {}
        },
        cellStyler: (rowData: any, cellVal: any) => {
            return {}
        },
        pageSize: 10
    }
    state = {
        pages: [],
        page: 0,
        selectedIdx: -1
    }
    props = {
        maxHeight: "",
        collumnNames: [""],
        collumnWidth: [0],
        url: "",
        method: "",
        dataSelectors: [(d: any) => {
            return (<div/>)
        }],
        rowDataSelector: (d: any) => {
            return d["rows"]
        },
        onRowSelected: (row: any) => {
        },
        onPageLoadFinished: (pageData: any) => {
        },
        rowStyler: (rowData: any) => {
            return {}
        },
        cellStyler: (rowData: any, cellVal: any) => {
            return {}
        },
        pageSize: 10
    }
    private last_page_size: number;
    private blockLoad: boolean;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.blockLoad = false;
        this.last_page_size = -1;
    }

    addRowToTop(row: any) {
        let p = this.state.pages;
        let d = [row].concat(p);
        this.setState({
            pages: d,
            selectedIdx: 0
        });
    }

    updateSelectedRow(row: any) {
        let p = this.state.pages.concat([]);
        let i = this.state.selectedIdx;

        p[i] = row as never;
        this.setState({
            pages: p
        });
    }

    header() {
        let rows = [];

        for (let i = 0; i < this.props.collumnNames.length; i++) {
            rows.push(<div key={i}
                           className={"col-sm-" + this.props.collumnWidth[i]}>{this.props.collumnNames[i]}</div>)
        }

        return (
            <div className="row header">
                {rows}
            </div>
        );
    }

    clear() {
        let l = {
            pages: [],
            page: 0,
            blockLoad: false
        };
        this.last_page_size = -1;
        this.blockLoad = false;
        this.setState(l);
    }

    reload(state: any) {
        this.clear();
        this.loadPage(Object.assign(this.state, state), this.props);
    }

    pageOptn(state: any, props: any) {
        return {
            method: props.method,
            headers: {}
        };
    }

    pageParams(state: any, props: any) {
        return "?keys= &values= count=10&page=" + state.page;
    }

    selfLoadPage() {
        this.loadPage(this.state, this.props);
    }

    loadPage(state: any, props: any) {
        if (this.last_page_size === 0 || (this.last_page_size % this.props.pageSize !== 0 && this.last_page_size !== -1)) {
            return;
        }

        this.blockLoad = true;
        let that = this;
        let optn = this.pageOptn(state, props);

        let param = this.pageParams(state, props);

        customFetch(props.url + param,
            (data: any) => {
                that.success(data);
                that.addPage(that.props.rowDataSelector(data), state);

                that.blockLoad = false;
                that.props.onPageLoadFinished(data);
            },
            (data: any) => {
                that.blockLoad = false;
                that.props.onPageLoadFinished(data);
            },
            optn
        );
    }

    success(data: any) {

    }

    addPage(page: [], state: any) {
        this.last_page_size = page.length;
        this.setState(Object.assign(this.state, {pages: this.state.pages.concat(page)}));
    }

    selectedRowData() {
        if (this.state.selectedIdx >= 0 && this.state.selectedIdx < this.state.pages.length) {
            return this.state.pages[this.state.selectedIdx];
        }
        return null;
    }

    selectedRowData_(state: any) {
        return state.pages[state.selectedIdx];
    }

    rowClicked(idx: number, data: any) {
        this.setState({
            selectedIdx: idx
        });
        this.props.onRowSelected(this.selectedRowData_(Object.assign(this.state, {
            selectedIdx: idx
        })));
    }

    renderRow(j: number, data: any) {
        let cols = [];

        for (let i = 0; i < this.props.collumnNames.length; i++) {
            let val = this.props.dataSelectors[i](data);
            cols.push(<div key={i.toString() + "-" + j.toString()} style={this.props.cellStyler(data, val)}
                           className={"col-sm-" + this.props.collumnWidth[i]}>{val}</div>)
        }

        return (
            <div key={j} className={"row datarow" + (this.state.selectedIdx === j ? " selected_row" : "")}
                 onClick={(event => this.rowClicked(j, data))} style={this.props.rowStyler(data)}>
                {cols}
            </div>)
    }

    renderRows() {
        let rows = [];

        for (let i = 0; i < this.state.pages.length; i++) {
            rows.push(this.renderRow(i, this.state.pages[i]));
        }

        return rows;
    }

    viewScrolled(event: React.UIEvent) {
        let percDown = (event.currentTarget.scrollTop + event.currentTarget.clientHeight) / event.currentTarget.scrollHeight;
        if (percDown > 0.75) {
            if (!this.blockLoad) {
                this.loadPage(Object.assign(this.state, {page: this.state.page + 1}), this.props);
            }
        }
    }

    render() {
        return (
            <div className="container custom_grid">
                {this.header()}
                <div className="custom_grid_rows" style={{maxHeight: this.props.maxHeight}} onScroll={(event => {
                    this.viewScrolled(event)
                })}>
                    {this.renderRows()}
                </div>
            </div>
        );
    }
}

export default DataGrid;