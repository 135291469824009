import DataGrid from "../data/dataGrid";

class ResourceView extends DataGrid {
    pageParams(state: any, props: any) {
        if (state["report_data"] != null) {
            let id = state["report_data"]["id"];
            return "?keys=reportId&values=" + id + "&page=" + state.page;
        }
        return "";
    }
}

export default ResourceView;