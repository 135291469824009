import React from "react";
import NavBar from "../components/shared/navBar";
import baseComponent from "../components/general/baseComponent";
import {Console} from "inspector";

class Page extends baseComponent {
    public static cur_page: Page;
    props = {
        navBar: React.createRef<NavBar>()
    }

    state = {
        hh_granted: false,
        require_admin: false
    }

    constructor(props: any) {
        super(props);
        this.props = props;
        Page.cur_page = this;
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
        //NavBar.cur_navbar.doLoginChecks(this);
    }

    componentDidMount() {
        NavBar.cur_navbar.doLoginChecks(this);
    }

    requireHH() {
        if (this.props.navBar.current) {
            NavBar.cur_navbar.need_hh = true;
            NavBar.cur_navbar.doLoginChecks(this);
        }
    }

    onFieldLogin() {
        console.log("Field Logged In");
    }

    onHHLogin() {
        console.log("HH Logged In");
        this.setState({hh_granted: true});
    }

    renderHHRequired() {
        return (<div></div>);
    }

    renderNonHH() {
        return (<div><h1>Seeking HH Grant...</h1></div>);
    }

    renderAlways(){
        return null as any;
    }

    render() {
        if (this.state.require_admin && localStorage.getItem("isAdmin") == "false"){
            document.location.href = "/main/home";
        }

        var renderAlways = this.renderAlways();

        return (<div>
            {(renderAlways == null ? (this.state.hh_granted ? this.renderHHRequired() : this.renderNonHH()) :  renderAlways)}
        </div>);
    }
}

export default Page;
