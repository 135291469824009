import React from "react";
import Popup from "../../components/general/popup";

class Registration extends Popup {
    constructor(props: any) {
        super(props);
        this.props = props;

        this.state.colour = "warning";
        this.state.header_text = "Registered Account";
        this.state.body_text = "";
    };

    body_html() {
        return (
            <div>
                <div className="alert alert-info">
                    <strong>Account Registered! </strong><br/>
                    <a>You won't be able to login until your
                        account
                        is activated
                        by an administrator.<br/>You will receive an email when activated!</a>
                </div>
            </div>);
    }
}

export default Registration;