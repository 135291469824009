import React from "react";
import Page from "../../page";
import Card from "../../../components/general/card";
import Popup from "../../../components/general/popup";
import Alert from "../../../components/general/alert";
import ReportView from "../../../components/job/ReportView";
import '../../../styling/bootstrap.scss'
import '../../../components/data/dataGrid.scss'
import ResourceManager from "../../../components/job/resourceManager";
import doFetch from "../../../scripts/fetch";
import JobDataView from "../../../components/job/JobDataView";

class JobReport extends Page {
    state = {
        hh_granted: false,
        require_admin: false,
        id: 0,
        report_data: {},
        date: "",
        report: "",
        spend: "",
        vehc: "",
        hs: "",
        perc: 0,
        mail_to: "",

        inputs_readonly: true,
        delete_state: false,
        send_email_on_save: false,
        ignore_wrong_date: false,
        monitoring_deployed: false
    }
    private popup_ref: React.RefObject<Popup>;
    private readonly report_view_ref: React.RefObject<ReportView>;
    private readonly selected_error_ref: React.RefObject<Alert>;
    private readonly view_error_ref: React.RefObject<Alert>;
    private readonly report_error_ref: React.RefObject<Alert>;
    private readonly resource_ref: React.RefObject<ResourceManager>;
    private readonly job_data_ref: React.RefObject<JobDataView>;
    private readonly general_input: React.RefObject<any>;
    private first = true;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.popup_ref = React.createRef();
        this.report_view_ref = React.createRef();
        this.selected_error_ref = React.createRef();
        this.view_error_ref = React.createRef();
        this.report_error_ref = React.createRef();
        this.resource_ref = React.createRef();
        this.job_data_ref = React.createRef();
        this.general_input = React.createRef();
    }

    findIdAndSearch() {
        const search = window.location.search;
        let id = new URLSearchParams(search).get('id');

        if (id != null && id.length > 0) {
            let n: number = parseInt(id, 10);

            if (n != null && n.toString() === id) {
                this.resource_ref.current?.setState({id: id});
                this.setState({id: id});
                this.job_data_ref.current?.selfLoadData(Number(id));
                this.report_view_ref.current?.selfLoadPage();
                return;
            }
        }
        this.setState({id: -1});
    }

    onHHLogin() {
        this.setState({hh_granted: true});
        if (this.first) {
            this.findIdAndSearch();
            this.first = false;
        }
        //this.resource_ref.current?.onHHLogin();
    }

    componentDidMount() {
        this.requireHH();

    }

    makeDateInRange(tDate: any): any {
        let jData = this.job_data_ref.current?.state.job_data as any;
        let curDate = new Date().toString().split(" ")[0].split("-");
        let selDate = new Date(tDate).toISOString().split(".")[0].split("T")[0].split("-");
        if (jData) {
            let minDate = jData["JOB_DATE"].split(" ")[0].split("-");
            let maxDate = jData["JOB_END"].split(" ")[0].split("-");

            for (let i = 0; i < 3; i++) {
                if (selDate[i] < minDate[i] || selDate[i] > maxDate[i] || selDate[i] > curDate[i]) {
                    return jData["JOB_END"];
                } else if (selDate[i] != minDate[i] || selDate[i] != maxDate[i]) {
                    return tDate;
                }
            }
        }
        return tDate;
    }

    reportSelectionChanged(row: any) {
        let that = this;
        this.setState({
            date: row["relevantDate"].split("T")[0],
            report: row["general"],
            spend: row["unplanned"],
            vehc: row["vehicles"],
            hs: row["healthnsafety"],
            perc: row["percentageComplete"],
            monitoring_deployed: row["monitoringDeployed"],
            report_data: row,
            delete_state: false,
            inputs_readonly: false,
            send_email_on_save: !row["emailSent"]
        });
        this.resource_ref.current?.setState({
            id: row["id"],
            inputs_readonly: false
        });
        this.resource_ref.current?.reload({report_data: row});
        setTimeout(() => {
            that.general_input.current?.focus();
        }, 1000);
    }

    render_report_view() {
        return (
            <Card colour="primary" title="Job Reports">
                <div>
                    <ReportView ref={this.report_view_ref} url="crud/reports/search"
                                collumnNames={["Id", "Created By", "Report Date", "Edited", "Created", "Email Sent"]}
                                collumnWidth={[1, 3, 2, 3, 2, 1]}
                                dataSelectors={[
                                    (d => d["id"]),
                                    (d => d["createdByEmail"]),
                                    (d => d["relevantDate"].split("T")[0]),
                                    (d => d["editedLast"].replace("T", " ").split(".")[0]),
                                    (d => d["createdAt"].replace("T", " ").split(".")[0]),
                                    (d => d["emailSent"] ? "Y" : "N")
                                ]}
                                rowDataSelector={(d: any) => {
                                    return d;
                                }}
                                onRowSelected={(row => {
                                    this.reportSelectionChanged(row)
                                })}

                    />

                    <Alert ref={this.view_error_ref}/>

                    <div>
                        <button className="btn btn-success" onClick={(event => {
                            this.newReport()
                        })} type="button">New
                        </button>
                        <button className="btn btn-warning" onClick={(event => {
                            this.cloneReport()
                        })} type="button">Clone
                        </button>
                        <button className="btn btn-danger" id="deleteBtn" onClick={(event => {
                            this.deleteReportBtn()
                        })}
                                type="button"
                                style={{display: this.state.delete_state ? "none" : "inline-block"}}>Delete
                        </button>
                        <div className="" id="deleteConfirm"
                             style={{
                                 border: "red 2px solid",
                                 padding: "5px",
                                 display: this.state.delete_state ? "inline-block" : "none"
                             }}>
                            <button className="btn btn-secondary" onClick={(event => {
                                this.cancelDelete()
                            })} type="button">Cancel
                            </button>
                            <button className="btn btn-danger" onClick={(event => {
                                this.deleteReport()
                            })} type="button">Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Card>

        );
    }

    newReport() {
        this.clearDetails();
        let that = this;

        let date = new Date(this.makeDateInRange(Date.now()));

        let dateStr = date.getFullYear() + "-" + this.padToTwoInt(date.getMonth() + 1) + "-" + this.padToTwoInt(date.getDate());

        doFetch("crud/reports", "post",
            (data: any) => {
                that.report_view_ref.current?.addRowToTop(data);
                that.setState({send_email_on_save: true});
                that.reportSelectionChanged(data);
            },
            (data: any) => {
                that.view_error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
            },
            {},
            {
                general: "",
                unplanned: "",
                vehicles: "",
                healthnsafety: "",
                str_relevantDatestr_relevantDate: dateStr,
                str_hhjobid: that.state.id
            }
        );
    }

    cloneReport() {
        let that = this;
        if (this.state.report_data.hasOwnProperty("id")) {
            doFetch("crud/reports/clone", "post",
                (data: any) => {
                    that.report_view_ref.current?.addRowToTop(data);
                    that.setState({send_email_on_save: true});
                    that.reportSelectionChanged(data);
                },
                (data: any) => {
                    that.view_error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
                },
                {},
                that.state.report_data
            );
        } else {
            that.view_error_ref.current?.changeDetails("warning", "None Selected!", "Please select a report to clone first!", true);
        }
    }

    deleteReportBtn() {
        this.setState({delete_state: true});
    }

    deleteReport() {
        let that = this;
        if (this.state.report_data.hasOwnProperty("id")) {
            doFetch("crud/reports/" + (this.state.report_data as any)["id"], "delete",
                (data: any) => {
                    let c = that.report_view_ref.current;
                    if (c) {
                        that.view_error_ref.current?.changeDetails("success", "Report Deleted!", "Your Report has been deleted!", true);
                        that.report_view_ref.current?.reload(that.report_view_ref.current);
                        that.setState({
                            inputs_readonly: true,
                            date: "",
                            report: "",
                            spend: "",
                            vehc: "",
                            hs: "",
                            monitoring_deployed: false,
                            perc: 0
                        });
                        that.resource_ref.current?.clear();
                        that.resource_ref.current?.setState({
                            resource: "",
                            role: "",
                            start: "",
                            end: "",
                            hs: "",
                            monitoring_deployed: false,
                            perc: 0,
                            inputs_readonly: true
                        });
                    }
                },
                (data: any) => {
                    that.view_error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
                }
            );
        } else {
            that.view_error_ref.current?.changeDetails("warning", "None Selected!", "Please select a report to delete first!", true);
        }
        this.setState({delete_state: false});
    }

    cancelDelete() {
        this.setState({delete_state: false});
    }

    fillDateToday() {
        if (this.state.inputs_readonly) return;

        let date = new Date();
        let dateStr = date.getFullYear() + "-" + this.padToTwoInt(date.getMonth() + 1) + "-" + this.padToTwoInt(date.getDate());
        this.setState({
            date: dateStr
        });
    }

    fillDateYesterday() {
        if (this.state.inputs_readonly) return;

        let date = new Date();
        date.setDate(date.getDate() - 1);
        let dateStr = date.getFullYear() + "-" + this.padToTwoInt(date.getMonth() + 1) + "-" + this.padToTwoInt(date.getDate());
        this.setState({
            date: dateStr
        });
    }

    render_selected_report() {
        let data_populated = this.state.report_data.hasOwnProperty("id");
        let no_elm = data_populated ? null : (
            <div>
                <h4>No Report Selected</h4>
                <p>Please Create A New Report Or Select An Existing One.</p>
            </div>);
        return (
            <Card colour="dark" title="Selected Report">
                <div>
                    {no_elm}
                    <div style={{display: data_populated ? "" : "none"}}>
                        <h4>Report Details</h4>
                        <div className="container custom_grid" id="report_details" style={{
                            overflowY: "visible",
                            minHeight: "fit-content  !important",
                            maxHeight: "unset !important"
                        }}>
                            <div>
                                <label className="col-sm-2 col-form-label"
                                       htmlFor="report_date" style={{width: "fit-content"}}>Report Date (<u
                                    onClick={() => {
                                        this.fillDateToday()
                                    }}>Today</u>, <u onClick={() => {
                                    this.fillDateYesterday()
                                }}>Yesterday</u>)</label>
                                <input className="form-control" id="report_date" type="date" name="date"
                                       value={this.state.date} onChange={(event => this.onValChange(event))}
                                       readOnly={this.state.inputs_readonly}/>

                                <label className="col-sm-2 col-form-label"
                                       htmlFor="update_report_body">General Report</label>
                                <textarea className="form-control" id="update_report_body"
                                          placeholder="General Report to include progress, any issues and anything which should be noted for future years"
                                          style={{height: "20vh"}} name="report"
                                          value={this.state.report}
                                          ref={this.general_input}
                                          onChange={(event => this.onValChange(event))}
                                          readOnly={this.state.inputs_readonly}/>

                                <label className="col-sm-2 col-form-label"
                                       htmlFor="unplanned_spend_body">Unplanned Spend Details</label>
                                <textarea className="form-control" id="unplanned_spend_body"
                                          placeholder="Unplanned Spend"
                                          style={{height: "20vh"}} name="spend"
                                          value={this.state.spend}
                                          onChange={(event => this.onValChange(event))}
                                          readOnly={this.state.inputs_readonly}/>

                                <label className="col-sm-2 col-form-label"
                                       htmlFor="vehicles_used_body">Vehicles Used</label>
                                <textarea className="form-control" id="vehicles_used_body"
                                          placeholder="To include vehicle reg and milage"
                                          style={{height: "20vh"}} name="vehc"
                                          value={this.state.vehc}
                                          onChange={(event => this.onValChange(event))}
                                          readOnly={this.state.inputs_readonly}/>

                                <label className="col-sm-2 col-form-label"
                                       htmlFor="hs">Health & Safety</label>
                                <textarea className="form-control" id="hs"
                                          placeholder="Health & Safety report to include any minor near misses or learnings"
                                          style={{height: "20vh"}} name="hs"
                                          value={this.state.hs}
                                          onChange={(event => this.onValChange(event))}
                                          readOnly={this.state.inputs_readonly}/>


                                <label className="col-sm-2 col-form-label"
                                       htmlFor="perc">Percentage of Project Complete</label>
                                <textarea className="form-control" id="perc" placeholder="% Complete"
                                          name="perc"
                                          value={this.state.perc}
                                          onChange={(event => this.onValChange(event))}
                                          readOnly={this.state.inputs_readonly}/>

                                <div className="progress">
                                    <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                                         role="progressbar"
                                         style={{width: this.state.perc + "%"}}/>
                                    <div className="progress-bar bg-warning"
                                         role="progressbar"
                                         style={{width: (100 - this.state.perc) + "%"}}/>
                                </div>
                                <div>
                                    <input className="form-check-input" id="monitoring_deployed"
                                           type="checkbox"
                                           checked={this.state.monitoring_deployed} name="monitoring_deployed"
                                           onChange={(event => this.onValChange(event))}/>
                                    <label className="form-check-label"
                                           htmlFor="monitoring_deployed">Network Monitoring Deployed?</label>
                                </div>
                            </div>

                            <Alert ref={this.selected_error_ref}/>

                            <hr/>
                            <ResourceManager ref={this.resource_ref}/>
                            <hr/>

                            <h4>Project Tasks</h4>

                            <hr/>

                            <Alert ref={this.report_error_ref}/>

                            <div className="container" style={{minWidth: "100%"}}>
                                <div className="row">
                                    <div className="col-sm">
                                        <input className="form-check-input" id="send_email_on_save"
                                               type="checkbox"
                                               checked={this.state.send_email_on_save} name="send_email_on_save"
                                               onChange={(event => this.onValChange(event))}
                                            /*disabled={(this.state.report_data as any)["emailSent"] == false}*//>
                                        <label className="form-check-label"
                                               htmlFor="send_email_on_save">Send Report Email</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <input className="form-check-input" id="ignore_wrong_date"
                                               type="checkbox"
                                               checked={this.state.ignore_wrong_date} name="ignore_wrong_date"
                                               onChange={(event => this.onValChange(event))}/>
                                        <label className="form-check-label"
                                               htmlFor="ignore_wrong_date">Allow Dates Outside Job Range</label>
                                    </div>
                                </div>
                            </div>

                            <label className="col-form-label"
                                   htmlFor="mail_to">Mailing To Additionally <small>Separate email with commas
                                (,)</small></label>
                            <textarea className="form-control" id="mail_to"
                                      placeholder="user@domain.com, user2@domain2.co.uk"
                                      name="mail_to"
                                      value={this.state.mail_to}
                                      onChange={(event => this.onValChange(event))}/>

                            <button className="btn btn-success" onClick={(event => {
                                this.saveReport()
                            })} type="button">{this.state.send_email_on_save ? "Send Report" : "Save Report"}
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

    saveReport() {
        let that = this;

        console.log(this.state.report_data);

        if (this.state.report_data.hasOwnProperty("id")) {
            if (this.state.date != this.makeDateInRange(this.state.date) && !this.state.ignore_wrong_date) {
                that.report_error_ref.current?.changeDetails("warning", "Invalid Report Date!", "Report date falls outside of the projects range, or is in the future.", true);
                return;
            }

            doFetch("crud/reports/" + (this.state.report_data as any)["id"], "post",
                (data: any) => {
                    let c = that.report_view_ref.current;
                    if (c) {
                        that.report_error_ref.current?.changeDetails("success", "Report Saved!", "Your Report has been saved!", true);
                        c.updateSelectedRow(data)

                        if (that.state.send_email_on_save) {
                            doFetch("crud/reports/emailout?reportId=" + (this.state.report_data as any)["id"] + "&emailList=" + this.state.mail_to, "post",
                                (data: any) => {
                                    that.report_error_ref.current?.changeDetails("success", "Report Emailed!", "Your Report has been saved and emailed out!", true);
                                    that.setState({send_email_on_save: false});
                                },
                                (data: any) => {
                                    that.report_error_ref.current?.changeDetails("warning", "Report Saved. But an Error occurred!", data["detail"], true);
                                }
                            );
                        }
                    }
                },
                (data: any) => {
                    that.report_error_ref.current?.changeDetails("danger", "An Error occurred!", data["detail"], true);
                },
                {},
                {
                    general: this.state.report,
                    unplanned: this.state.spend,
                    vehicles: this.state.vehc,
                    healthnsafety: this.state.hs,
                    str_PercentageComplete: this.state.perc.toString(),
                    str_relevantDate: this.state.date,
                    monitoringDeployed: this.state.monitoring_deployed
                }
            );
        } else {
            that.report_error_ref.current?.changeDetails("warning", "None Selected!", "Please select a report first!", true);
        }
    }

    clearDetails() {
        this.setState({
            date: "",
            report: "",
            spend: "",
            vehc: ""
        });
    }

    renderHHRequired() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="./home">Home</a></li>
                <li className="breadcrumb-item"><a href="./jobmanager">Job Manager</a></li>
                <li className="breadcrumb-item">Job Report</li>
            </ol>

            {this.state.id === -1 ? (
                <Popup header_text="Missing Parameter" colour="danger" visible={true} onClose={() => {
                    document.location.href = "./jobmanager"
                }}>
                    <div>
                        <Alert colour="danger" strongText="No Id Provided!"
                               weakText="No Id has been provided in the url, please return to the previous page."
                               visible={true}/>
                    </div>
                </Popup>
            ) : (
                <div>
                    <Card colour="success" title="Selected Job">
                        <JobDataView ref={this.job_data_ref}/>
                    </Card>
                    {this.render_report_view()}
                    {this.render_selected_report()}
                </div>
            )}
        </div>)
    }
}

export default JobReport;
