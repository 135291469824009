import baseComponent from "../general/baseComponent";
import React from "react";
import DataGrid2 from "./dataGrid2";
import What3WordsInput from "../shared/what3words";
import embeddableText from "../../scripts/embeddableText";
import Alert from "../general/alert";

class DataView extends baseComponent {
    static defaultProps = {
        onCheckInputs: (values: string[]) => {
        }
    }
    state = {
        row_data: {},
        field_value_memory: [""],
        errors: [] as string[]
    }
    props = {
        data_grid: React.createRef<DataGrid2>(),

        field_labels: [] as any[],
        field_value_selector: [[""]],
        field_readonly: [false],
        field_types: ["string"],
        onCheckInputs: (values: string[]) => {
        }
    }

    constructor(props: any) {
        super(props);
        this.props = props;

        let e = [] as string[];
        this.props.field_value_selector.forEach((v, i) => e.push(""))

        this.state = {...this.state, errors: e};

        //enforced prop arr length
        let epal = this.props.field_labels.length;
        if (this.props.field_value_selector.length != epal || this.props.field_readonly.length != epal || this.props.field_types.length != epal) {
            throw "DataGrid Prop Arrays Must Match In Length";
        }
    }

    public reconstructObject() {
        let that = this;
        let fieldObject: any = {...this.state.row_data};

        this.props.field_value_selector.forEach((v: string[], field_id: number) => {
            fieldObject = that._reconstructObjectLayer(fieldObject, v, 0, field_id);
        });

        return fieldObject;
    }

    data_grid_row_selected(index: number, row_data: any) {
        let m: any[] = [];
        this.props.field_value_selector.forEach((v: string[]) => {
            m.push(this.extractRowData(row_data, v));
        })

        this.setState({
            row_data: row_data,
            field_value_memory: m
        });
    }

    render() {
        return (<div>
            {this.generateFields()}
        </div>);
    }

    public ensureValuesCanCast() {
        var that = this;
        var success = true;

        var errors = that.state.errors;

        this.state.field_value_memory.forEach((v, i) => {
            var vv = that.getAndCastValues(i);
            if (Number.isNaN(vv)) {
                errors[i] = "Unable to convert input to " + that.props.field_types[i];
                success = false;
            } else {
                that.state.errors[i] = "";
            }
        })

        that.setState({errors: errors});
        return success;
    }

    private getAndCastValues(field_i: number) {
        var v = this.state.field_value_memory[field_i];

        switch (this.props.field_types[field_i]) {
            case "float":
                return parseFloat(v);
            case "int":
                return this.filterInt(v);
            default:
                return v;
        }
    }

    private filterInt(value: string) {
        return /^[-+]?(\d+|Infinity)$/.test(value) ? Number(value) : NaN;
    }

    private _reconstructObjectLayer(originalLayerObject: any, selector: string[], layer: number, field_i: number): any {
        let sel = selector[layer];
        let val = originalLayerObject[sel];
        if (selector.length - 1 > layer) {
            originalLayerObject[sel] = this._reconstructObjectLayer(val, selector, layer + 1, field_i);
        } else {
            originalLayerObject[sel] = this.getAndCastValues(field_i);
        }
        return originalLayerObject;
    }

    private extractRowData(data: any, selector_chain: string[]) {
        let d = data;
        selector_chain.forEach((v: string, i: number, a: string[]) => {
            if (d.hasOwnProperty(v)) {
                d = d[v];
            } else {
                d = "";
            }
        })
        return d;
    }

    private fieldChange(event: any) {
        let f = this.state.field_value_memory;
        if (event.target.type == "checkbox") {
            f[event.target.name] = event.target.checked;
        } else {
            f[event.target.name] = event.target.value;
        }
        this.setState({
            field_value_memory: f
        });
        this.props.onCheckInputs(f);
    }

    private generateFields(): JSX.Element[] {
        let fields: JSX.Element[] = [];

        this.props.field_labels.forEach((v: string, i: number, a: string[]) => {
            let label = (
                <label key={i + "label"} htmlFor={i.toString() + "input"} className="col-form-label">{v}</label>);
            let input;

            let val = this.state.field_value_memory[i];

            switch (this.props.field_types[i]) {
                case "boolean":
                    input = (<input className="form-check-input" type="checkbox"
                                    checked={val != null ? (val.toString() == "true") : false}
                                    name={i.toString()} id={i.toString() + "input"}
                                    onChange={(event => this.fieldChange(event))}
                                    readOnly={this.props.field_readonly[i]}/>);
                    break;
                case "what3words":
                    input = (<What3WordsInput name={i.toString()} value={val != null ? val.toString() : ""}
                                              onChange={(event => this.fieldChange(event))}></What3WordsInput>);
                    break;
                case "embeddedFiles":
                    input = (<div>{(val != null ? embeddableText(val) : "")}</div>);
                    break;
                default:
                    input = (<textarea className="form-control" placeholder={v}
                                       style={{height: "10vh"}}
                                       value={val != null ? val : ""}
                                       name={i.toString()} id={i.toString() + "input"}
                                       onChange={(event => this.fieldChange(event))}
                                       readOnly={this.props.field_readonly[i]}/>);
                    break;
            }

            let error = (<Alert visible={this.state.errors[i].length > 0} colour="danger" strongText="Input Invalid!"
                                weakText={this.state.errors[i]}/>);

            fields.push(<div key={i}>
                {label}
                {input}
                {error}
            </div>);
        });

        return fields;
    }
}

export default DataView;
