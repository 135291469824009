import React from "react";
import '../../styling/bootstrap.scss';
import './login.scss';
import Registration from "./registration";
import Recovery from "./recovery";
import Alert from "../../components/general/alert";
import baseComponent from "../../components/general/baseComponent";
import LoginChecks from "../../components/shared/loginChecks";
import doFetch from "../../scripts/fetch";

class Login extends baseComponent {
    state = {
        email: "",
        password: ""
    }
    private readonly registrationRef: React.RefObject<Registration>;
    private readonly recoveryRef: React.RefObject<Recovery>;
    private readonly loginErrorRef: React.RefObject<Alert>;
    private readonly password_ref: React.RefObject<any>;
    private readonly email_ref: React.RefObject<any>;
    private login_checks: LoginChecks;

    constructor(props: any) {
        super(props);

        this.registrationRef = React.createRef();
        this.recoveryRef = React.createRef();
        this.loginErrorRef = React.createRef();
        this.password_ref = React.createRef();
        this.email_ref = React.createRef();
        this.login_checks = new LoginChecks({});
    }

    componentDidMount() {
        let bgRnd = (new Date()).getMilliseconds() % 6;
        document.body.classList.add("bg" + bgRnd);
        this.login_checks.checkFieldLogin(
            function () {
                document.location.href = "/main/home";
            },
            function () {
            }
        );
        this.email_ref.current?.focus();
    }

    render() {
        return (
            <div>
                <div className="center" style={{minWidth: "fit-content"}}>
                    <div style={{textAlign: "center"}}>
                        <h1 className="visible-text">PMY Field Portal (Beta)</h1>
                    </div>
                    <br/>
                    <div className="card border-primary mb-3">
                        <div className="card-header">Field Portal Login</div>
                        <div className="card-body">
                            <div className="alert alert-primary">
                                <strong>Authorised Users Only!</strong><br/>
                                <strong>By signing in, I agree</strong> that cookies and data can be stored
                                on my
                                browser.<br/>
                                <strong>New Users: </strong> <a>Fill your desired email and password below, then press
                                Register. You will then have to wait for an administrator to activate your account.</a>
                            </div>
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label">Email</label>
                                    <input className="form-control" id="input_email" placeholder="Email"
                                           type="text" name="email" ref={this.email_ref}
                                           value={this.state.email} onChange={(event => this.onValChange(event))}
                                           onKeyPress={(e => this.onKeyCheckFocusFlow(e, this.password_ref, null))}/>

                                    <label className="col-form-label mt-2">Password</label>
                                    <input className="form-control" name="password" id="input_password"
                                           placeholder="Password" type="password"
                                           value={this.state.password} ref={this.password_ref}
                                           onChange={(event => this.onValChange(event))}
                                           onKeyPress={(e => this.onKeyCheckFocusFlow(e, null, () => this.loginClicked()))}/>
                                </div>
                                <div>
                                    <Alert ref={this.loginErrorRef}/>
                                    <div id="login_error"/>
                                    <button className="btn btn-warning" onClick={(event => {
                                        this.showRecovery();
                                    })}
                                            type="button">Recover
                                        Account
                                    </button>
                                    <div className="right">
                                        <button className="btn btn-primary" onClick={(event => {
                                            this.registerClicked();
                                        })}
                                                type="button">Register
                                        </button>
                                        <button className="btn btn-success" onClick={(event => {
                                            this.loginClicked();
                                        })} type="button">Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Recovery ref={this.recoveryRef}/>
                <Registration ref={this.registrationRef}/>
            </div>);
    }

    showRecovery() {
        this.recoveryRef.current?.setState({visible: true, email: this.state.email});
    }

    // uDetailOptions() {
    //     return {
    //         method: 'POST',
    //         headers: {
    //             "email": this.state.email,
    //             "password": this.state.password
    //         },
    //         credentials: "include",
    //         withCredentials: "include"
    //     };
    // }

    registerClicked() {
        let that = this;
        doFetch("auth/user/register", "post",
            (data: any) => {
                that.registrationRef.current?.show();
            },
            (data: any) => {
                that.loginErrorRef.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
            },
            {
                "email": that.state.email,
                "password": that.state.password
            }
        );
    }

    loginClicked() {
        let that = this;

        let det = {
            "email": that.state.email,
            "password": that.state.password
        };

        let headers;

        if (document.location.href.includes("localhost")) {
            headers = {...det, bodyAuth: "true"};
        } else {
            headers = det;
        }

        doFetch("auth/user/login", "post",
            (data: any) => {
                console.log(data["user"]);

                if (document.location.href.includes("localhost")) {
                    localStorage.setItem("uid", data["uid"]);
                    localStorage.setItem("token", data["token"]);
                }

                localStorage.setItem("isAdmin", data["user"]["isAdmin"]);

                let redirect_to = "/main/home";
                if (document.location.href.includes("?")) {
                    let paramString = document.location.href.split('?')[1];
                    let queryString = new URLSearchParams(paramString);

                    if (queryString.has("redirect") && queryString.has("params")) {
                        redirect_to = queryString.get("redirect") as string;
                        let quer = queryString.get("params") as string;
                        quer = quer.replace("¬", "&").replace("~", "=")
                        redirect_to += "?" + quer;
                    }
                }

                doFetch("auth/hirehop/grantuse", "post",
                    (data: any) => {
                        document.location.href = redirect_to;
                    }, (data: any) => {
                        document.location.href = redirect_to;
                    },
                    {
                        "userpassword": that.state.password
                    }
                )
            },
            (data: any) => {
                that.loginErrorRef.current?.changeDetails("danger", "An Error Occurred", data["detail"], true);
            },
            headers
        );
    }
}

export default Login;
