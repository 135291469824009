import Page from "../../page";
import React from "react";
import DataGrid2 from "../../../components/data/dataGrid2";
import DataView from "../../../components/data/dataView";
import Card from "../../../components/general/card";
import DataRow from "../../../components/data/dataRow";
import DataManager from "../../../components/data/dataManager";
import FileUpload from "../../../components/shared/fileUpload";
import doFetch from "../../../scripts/fetch";

class JobAssets extends Page {
    state = {
        hh_granted: false,
        barcode: "",
        selected_rows: [] as number [],
        require_admin: false
    }
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;
    private data_manager: React.RefObject<DataManager>;
    private file_upload: React.RefObject<FileUpload>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_manager = React.createRef<DataManager>();
        this.data_view = React.createRef<DataView>();
        this.file_upload = React.createRef<FileUpload>();
    }

    componentDidMount() {
        this.requireHH();
    }

    toggleRowSelected(id: number, state: React.ChangeEvent<HTMLInputElement>) {
        let sels = this.state.selected_rows;
        if (state.target.checked) {
            sels.push(id);
        } else {
            sels = sels.filter((v) => v != id);
        }
        this.setState({selected_rows: sels});
    }

    toggleAll() {
        let sels = this.state.selected_rows;
        if (sels.length > 0) {
            sels = [];
        } else {
            sels = [];
            this.data_grid.current?.state.data_rows.filter((v) => {
                sels.push(v["id"]);
                return true;
            })
        }
        console.log(sels);
        this.setState({selected_rows: sels});
    }

    copyLocation() {
        let that = this;
        let curRowData = that.data_view.current?.state.row_data as any;
        if (curRowData != null) {
            this.state.selected_rows.forEach((v) => {
                let row = that.data_grid.current?.state.data_rows.filter((r) => r["id"] == v)[0] as any;
                if (row != null) {
                    row.longitude = curRowData.longitude;
                    row.latitude = curRowData.latitude;
                    row.what3words = curRowData.what3words;
                    that.data_manager.current?.update(row);
                }
            })
            //that.data_view.current?.setState({row_data: curRowData});
            setTimeout(() => {
                that.data_grid.current?.reload();
                that.setState({selected_rows: []});
            }, 2000);
        }
    }

    pullFromHH() {
        const search = window.location.search;
        let id = new URLSearchParams(search).get('id');
        let that = this;
        doFetch("crud/assets/pullfromhh?jobid=" + id, "POST", (d) => {
            that.data_grid.current?.reload();
        }, (d) => {

        });
    }

    renderHHRequired() {
        const search = window.location.search;
        let id = new URLSearchParams(search).get('id');
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="./home">Home</a></li>
                <li className="breadcrumb-item"><a href="./jobmanager">Job Manager</a></li>
                <li className="breadcrumb-item">Job Assets</li>
            </ol>
            <FileUpload ref={this.file_upload} data_view={this.data_view} header_text={"File Upload"}
                        data_field_idx={6}></FileUpload>
            <Card colour="info" title="Job Assets">
                <div>
                    <DataGrid2 ref={this.data_grid} url={"crud/assets/search"}
                               row_selector={[]} header_titles={[(
                        <a onClick={() => this.toggleAll()} style={{textDecoration: "underline", color: "blue"}}>Toggle
                            All</a>), "Barcode", "Stock Name", "Creator", "Location"]}
                               header_sizes={[1, 1, 3, 3, 4]}
                               additonal_row_selector={[]}
                               url_paramaters={(state, props, page_num) => {
                                   return "?keys=hh_job_id&values=" + id + "&count=" + props.page_size + "&page=" + page_num
                               }}
                               row_styler={(row_data) => {
                                   return this.state.selected_rows.includes(row_data["id"]) ? {backgroundColor: "palegreen"} : {};
                               }}
                               cell_body_extractors={[
                                   (d: any) => {
                                       return (<input type="checkbox" onChange={(event) => {
                                           this.toggleRowSelected(d["id"], event);
                                           this.data_grid.current?.revertRowClicked()
                                       }} checked={this.state.selected_rows.includes(d["id"])}></input>)
                                   },
                                   (d: any) => {
                                       return (<div>{d["barcode"]}</div>)
                                   },
                                   (d: any) => {
                                       return (<div>{d["stockTitle"]}</div>)
                                   },
                                   (d: any) => {
                                       return (
                                           <div>{d["createdByEmail"]}</div>)
                                   },
                                   (d: any) => {
                                       return (
                                           <div>{d["what3words"] != null && d["what3words"].length > 0 ? (
                                               <a href={"https://what3words.com/" + d["what3words"]}
                                                  target="_blank">{d["what3words"]}</a>) : d["longitude"] + " , " + d["latitude"]}</div>)
                                   }
                               ]}
                               row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                                   this.data_view.current?.data_grid_row_selected(index, row_data)
                               }}
                    />
                    <div className="form-group">
                        <button className="btn btn-warning" onClick={(event => {
                            this.pullFromHH()
                        })} type="button">Pull From HH
                        </button>
                        <button className="btn btn-success" onClick={(event => {
                            this.copyLocation()
                        })} type="button">Copy Selected Location To Highlighted
                        </button>
                        <input className="form-control" placeholder="Barcode To Register"
                               type="text" name="barcode"
                               value={this.state.barcode} onChange={(event => this.onValChange(event))}/>
                    </div>
                    <DataManager data_grid={this.data_grid} action_url="crud/assets" ref={this.data_manager}
                                 add_enabled={true} update_enabled={true} delete_enabled={true}
                                 data_view={this.data_view}
                                 update_data_key={[]}
                                 default_obj={{HHJobId: id, barcode: this.state.barcode, stockId: 0}}
                    />
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Barcode", "Stock Title", "Longitude", "Latitude", "What3Words", "Photos", "Photo Links"]}
                              field_value_selector={[["barcode"], ["stockTitle"], ["longitude"], ["latitude"], ["what3words"], ["photoFile"], ["photoFile"]]}
                              field_readonly={[true, true, false, false, false, true, false]}
                              field_types={["string", "string", "float", "float", "what3words", "embeddedFiles", "string"]}
                              onCheckInputs={(values) => {
                                  this.data_view.current?.ensureValuesCanCast();
                                  // var e = this.data_view.current?.state.errors;
                                  // if (e) {
                                  //     if (this.data_grid.current?.state.data_rows.some((v) => (v["id"] as any).toString() == this.data_view.current?.state.field_value_memory[2])) {
                                  //         e[2] = "";
                                  //     } else {
                                  //         e[2] = "Does Not Match An Id In This Job";
                                  //     }
                                  //     this.data_view.current?.setState({errors: e});
                                  // }
                              }}
                    />

                    <button className="btn btn-success" onClick={(event => {
                        this.file_upload.current?.show();
                    })} type="button">Embed A File
                    </button>
                </div>
            </Card>
        </div>);
    }
}

export default JobAssets;
