import Page from "../../page";
import React from "react";
import DataGrid2 from "../../../components/data/dataGrid2";
import DataView from "../../../components/data/dataView";
import Card from "../../../components/general/card";
import DataRow from "../../../components/data/dataRow";
import DataManager from "../../../components/data/dataManager";
import embeddableText from "../../../scripts/embeddableText";
import FileEmbed from "../../../components/shared/fileEmbed";

class GeneralAnnouncements extends Page {
    private data_grid: React.RefObject<DataGrid2>;
    private data_view: React.RefObject<DataView>;
    private file_embed: React.RefObject<FileEmbed>;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.data_grid = React.createRef<DataGrid2>();
        this.data_view = React.createRef<DataView>();
        this.file_embed = React.createRef<FileEmbed>();
    }

    renderAlways() {
        return (<div>
            <ol className="custom_breadcrumb center-x">
                <li className="breadcrumb-item"><a href="../home">Home</a></li>
                <li className="breadcrumb-item"><a href="../admin">Admin</a></li>
                <li className="breadcrumb-item">Safety Announcements</li>
            </ol>
            <FileEmbed ref={this.file_embed} data_view={this.data_view} data_field_idx={0}></FileEmbed>
            <Card colour="info" title="Safety Announcements">
                <div>
                    <DataGrid2 ref={this.data_grid} url="crud/generalupdates/search"
                               row_selector={[]} header_titles={["Created At", "Description", "Creator"]}
                               header_sizes={[2, 8, 2]}
                               additonal_row_selector={[]}
                               cell_body_extractors={[
                                   (d: any) => {
                                       return (<div>{d["createdAt"].replace("T", " @ ")}</div>)
                                   },
                                   (d: any) => {
                                       return (<div>{embeddableText(d["body"])}</div>)
                                   },
                                   (d: any) => {
                                       return (
                                           <div>{d["createdBy"]}</div>)
                                   }
                               ]}
                               row_clicked={(index: number, row_data: any, row_element: React.RefObject<DataRow>) => {
                                   this.data_view.current?.data_grid_row_selected(index, row_data)
                               }}
                    />
                    <DataManager data_grid={this.data_grid} action_url="crud/generalupdates"
                                 add_enabled={true} update_enabled={true} delete_enabled={true}
                                 data_view={this.data_view}
                                 update_data_key={[]}
                                 default_obj={{body: ""}}
                    />
                    <DataView ref={this.data_view} data_grid={this.data_grid}
                              field_labels={["Body"]}
                              field_value_selector={[["body"]]}
                              field_readonly={[false]}
                              field_types={["string"]}
                    />
                    <button className="btn btn-success" onClick={(event => {
                        this.file_embed.current?.show();
                    })} type="button">Embed A File
                    </button>
                </div>
            </Card>
        </div>);
    }

}

export default GeneralAnnouncements;
